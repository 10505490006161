import React from 'react';
import * as echarts from 'echarts';
import ReactAudioPlayer from "react-audio-player";
// import {alertMap} from "../../../../utils/common";

export default class HeartChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    yWidth = 500;
    lastHeart = 0;
    lastHeartTime = 0;
    dataList = [];
    timeHandle = undefined;
    data = [];
    dataIndex=0;
    heart = 0;
    close_to=0;

    autoPlay = false;
    audioRunning = false
    audio1Ref = React.createRef();
    audio2Ref = React.createRef();
    audio3Ref = React.createRef();

    timeHandle2 = undefined
    timeHandle3 = undefined

    hasPeople = 0;
    hasHeart = 0;

    initChart = ()=>{
        for (let i = 1; i <= this.yWidth; i++) {
            this.yAxisData.push(null);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: ``,
                right: '0vw',
                textStyle: {
                    color: '#00B2FD',
                    fontSize: 20,
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '10%',
                height: '80%',
            },
            xAxis: {
                boundaryGap: false,
                data: this.xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#f2f2f2',
                    },
                },
            },
            yAxis: {
                min: -50,
                max: 120,
                interval: 1,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: ['#078CD6'],
                    },
                },
                boundaryGap: false,
            },
            series: {
                symbol: 'none' /* 去掉小圆点 */,
                name: '当前值',
                type: 'line',
                data: this.yAxisData,
                smooth:false /* ,//显示为平滑的曲线 */,
                lineStyle: {
                    color: '#FF7B3F',
                },
            },
        };

        const heartMap = document.getElementById(`heartChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap);
        }
    }

    getData(){
        let P = [0.1,0.2,0.3,1.5,2,3,4.5,6,7.5,9,10.5,12,13,14,15,14,13,12,10,8,5,0];
        let PR = [];
        let QRS = [0,-5,-10,0,5,10,40,60,75,88+parseInt(Math.random()*5),75,60,40,20,0,-20,-18,-15,-12,-10,-8,-5,-2,0];
        let ST = [];
        let T = [0,2,4,4.6,16,20,15,5,0];
        let U = [];
        for (let i=0;i<15;i++) PR.push((Math.random()*2).toFixed(2))
        for (let i=0;i<20;i++) ST.push((Math.random()*2).toFixed(2))
        for (let i=0;i<30;i++) U.push((Math.random()*2).toFixed(2))
        this.data = P.concat(PR).concat(QRS).concat(ST).concat(T).concat(U)
    }
    getData0(){
        let data = [];
        for (let i=0;i<120;i++){
            data.push(0)
        }
        this.data = data;
    }
    getDataRandom=()=>{
        let data = [];
        for (let i=0;i<120;i++){
            data.push(70-Math.random()*140)
        }
        this.data = data;
    }

    lastPlay = undefined
    scanData = ()=>{
        clearTimeout(this.timeHandle)
        if (this.heart < 70) this.heart = 70

        let flag = true;
        // 有人就有心跳
        if (flag && this.hasPeople>0){
            if (this.hasSport==1){//有体动 play3 体动情况
                if (this.lastPlay!==3) this.clearPlay()
                // if (!this.audioRunning){ this.playSound3(); }
                this.getDataRandom();
                this.lastPlay = 3
            }else{//静止
                if (this.lastPlay!==1) this.clearPlay()
                this.lastPlay = undefined
                this.getData()
            }
            flag = false
        }

        //没人（可能因为屏住呼吸，心跳有值就有心跳）
        if(flag && this.hasPeople===0){
            if (this.hasHeart===1){
                if (this.lastPlay!==1) this.clearPlay()
                this.lastPlay = undefined
                this.getData()
            }else{
                this.clearPlay()
                this.getData0();
                this.lastPlay = 0
            }
            flag = false;
        }

        //默认值，无数据
        if (flag){
            this.clearPlay()
            this.getData0();
            this.lastPlay = 0
            flag = false;
        }

        // if (flag && this.has_people==0){
        //     this.audio2Stop=false;
        //     if (!this.audioRunning){
        //         this.playSound2()
        //     }
        //     this.getData0();
        //     flag = false
        // }



        //
        // if(flag && this.heart<1){
        //     this.getData0();
        //     if (this.autoPlay && !this.audioRunning){
        //         this.audioRunning = true;
        //         this.playSound2()
        //     }
        //     flag = false
        // }else if(this.is_sleep>0){
        //     this.getDataRandom();
        // }else{
        //     this.audio2Stop = true;
        //     this.getData();
        // }

        if (this.dataIndex>this.data.length-1) this.dataIndex=0;

        let frame = 5;//默认
        for (let i=0;i<frame;i++){
            let ware = this.data[this.dataIndex++];
            this.yAxisData.push(ware);
            this.yAxisData.shift();
            this.playHigh(ware)
        }
        this.eChart.setOption(this.option);

        let timeout = parseInt(500*frame)
        if (this.heart>0) timeout = parseInt(500*frame/this.heart)
        this.timeHandle = setTimeout(this.scanData,timeout);
    }

    clearPlay=()=>{
        if (this.timeHandle2) clearTimeout(this.timeHandle2)
        if (this.timeHandle3) clearTimeout(this.timeHandle3)
        this.audioRunning = false
    }

    playHigh = (wave)=>{
        if (wave>=88){
            if (this.autoPlay){
                this.playSound1()
            }
        }
    }
    //正常峰值声音
    playSound1 = ()=>{
        if (this.autoPlay) {
            this.audio1Ref.current.audioEl.current.src = '/audio/breath/sound1.mp3';
            this.audio1Ref.current.audioEl.current.play();
        }
    }

    //有人没心率
    playSound2 = ()=>{
        if (this.autoPlay) {
            this.audioRunning = true;
            this.audio2Ref.current.audioEl.current.src = '/audio/breath/sound2.mp3';
            this.audio2Ref.current.audioEl.current.play();
        }
    }

    //有人乱动
    playSound3 = ()=>{
        if (this.autoPlay){
            this.audioRunning = true;
            this.audio3Ref.current.audioEl.current.src = '/audio/breath/sound2.mp3';
            this.audio3Ref.current.audioEl.current.play();
            console.log(this.audio3Ref)
        }
    }

    componentDidMount() {
        this.initChart();
        this.scanData();
    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle);
        this.timeHandle = undefined
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }


    componentDidUpdate() {
        let {heart,hasHeart,hasPeople,hasSport,close_to} = this.props;
        this.heart = heart
        this.hasSport = hasSport
        this.close_to = close_to
        this.hasPeople = hasPeople
        this.hasHeart = hasHeart
    }

    render() {
        return (
            <>
                <ReactAudioPlayer src={""} autoPlay={false} ref={this.audio1Ref} />
                <ReactAudioPlayer src={""} autoPlay={false} ref={this.audio2Ref} onEnded={()=>{
                    this.timeHandle2 =  setTimeout(()=>{this.playSound2();},20)
                }}/>

                <ReactAudioPlayer src={""} autoPlay={false} ref={this.audio3Ref} onEnded={()=>{
                    this.timeHandle3 =  setTimeout(()=>{this.playSound3();},40)
                }}/>

                <div
                    id={`heartChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%', backgroundColor: '#dfdfdf' }}
                    onClick={()=>{
                        this.autoPlay=!this.autoPlay;
                    }}
                ></div>
            </>
        );
    }
}
