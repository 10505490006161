

export const alertMap =(alertType)=>{
    // const DEVICE_TYPE_PEOPLE = 7;
    // const DEVICE_TYPE_BREATH = 9;
    // const OTHER_DEVICE_TYPE_SMOKE = 11;
    // const OTHER_DEVICE_TYPE_GAS = 12;
    // const OTHER_DEVICE_TYPE_WATER = 13;
    // const OTHER_DEVICE_TYPE_DOOR = 14;
    // const OTHER_DEVICE_TYPE_ROPE = 15;
    // const OTHER_DEVICE_TYPE_SOS = 16;
    // const OTHER_DEVICE_TYPE_BREATH_HEART = 17;
    const alertMaps = {
        '7': { title: '摔倒设备',	   audio: '/audio/bjyp-offline.mp3',  src1:"/images/icon/i01.png",src2:"/images/icon/i01-1.png"	},
        '9': { title: '呼吸设备',	   audio: '/audio/bjyp-offline.mp3',  src1:"/images/icon/i13.png",src2:"/images/icon/i13-1.png"	},
        '1':  { title: '卫生间摔倒',    audio: '/audio/bjyp-disappear.mp3', src1:"",                    src2:""                         },
        '2':  { title: '滞留报警',	  audio: '/audio/bjyp-bathtub.mp3',   src1:"/images/icon/i06.png",src2:"/images/icon/i06-1.png"   },
        '3':  { title: '摔倒报警',	  audio: '/audio/bjyp-disappear.mp3', src1:"/images/icon/i01.png",src2:"/images/icon/i01-1.png"   },
        '4':  { title: '异常离床报警',  audio: '/audio/bjyp-sleep.mp3',	  src1:"/images/icon/i05.png",src2:"/images/icon/i05-1.png"   },
        '5':  { title: '异常下床',	  audio: '',                          src1:"",                    src2:""                         },
        '6':  { title: '长时间无呼吸数据',audio: '/audio/bjyp-breath.mp3',  src1:"/images/icon/i13.png",src2:"/images/icon/i13-1.png"	},
        '11': { title: '烟雾报警',      audio: '/audio/bjyp-smoke.mp3',	  src1:"/images/icon/i10.png",src2:"/images/icon/i10-1.png"	},
        '12': { title: '燃气报警',  	   audio: '/audio/bjyp-gas.mp3',	  src1:"/images/icon/i11.png",src2:"/images/icon/i11-1.png"	},
        '13': { title: '浸水监测',	   audio: '/audio/bjyp-water.mp3',	  src1:"/images/icon/i12.png",src2:"/images/icon/i12-1.png"	},
        '14': { title: '门磁报警',	   audio: '/audio/bjyp-door.mp3',	  src1:"/images/icon/i09.png",src2:"/images/icon/i09-1.png"	},
        '15': { title: '拉绳报警',	   audio: '/audio/bjyp-rope.mp3',	  src1:"/images/icon/i07.png",src2:"/images/icon/i07-1.png"	},
        '16': { title: 'SOS报警',	   audio: '/audio/bjyp-sos.mp3',	  src1:"/images/icon/i08.png",src2:"/images/icon/i08-1.png"	},
        '18': { title: '电量不足',	   audio: '/audio/bjyp-battery.mp3',  src1:"",src2:""	},
        '19': { title: '离线报警',	   audio: '/audio/bjyp-offline.mp3',  src1:"",src2:""	},
    };
    return alertMaps[alertType]
}



// export const formatDate = (date) => {
//     var y = date.getFullYear();
//     var m = date.getMonth() + 1;
//     m = m < 10 ? '0' + m : m;
//     var d = date.getDate();
//     d = d < 10 ? ('0' + d) : d;
//     return y + '-' + m + '-' + d;
// }

/*
** 时间戳转换成指定格式日期
** eg. 
** dateFormat(11111111111111, 'Y年m月d日 H时i分')
** → "2322年02月06日 03时45分"
*/
export const dateFormat = (timestamp, formats) => {
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || 'Y-m-d';

    var zero = function (value) {
        if (value < 10) {
            return '0' + value;
        }
        return value;
    };

    var myDate = timestamp? new Date(timestamp): new Date();

    var year = myDate.getFullYear();
    var month = zero(myDate.getMonth() + 1);
    var day = zero(myDate.getDate());

    var hour = zero(myDate.getHours());
    var minite = zero(myDate.getMinutes());
    var second = zero(myDate.getSeconds());

    return formats.replace(/Y|m|d|H|i|s/ig, function (matches) {
        return ({
            Y: year,
            m: month,
            d: day,
            H: hour,
            i: minite,
            s: second
        })[matches];
    });
};




