import React from 'react';
import * as echarts from 'echarts';

export default class BreathMap extends React.Component {
  heart = 0;
  xAxisData = [];
  yAxisData = [];
  totalFlowRate;
  totalFlowRateOption;
  componentDidMount() {
    // var data = [116,129,135,86,73,73,73,73,73,73,73,85,73,68,85,90,90,90,90,180,300];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 10; i++) {
      this.yAxisData.push(null);
    }
    this.totalFlowRateOption = {
      animation: false,
      title: {
        text: `--BPM`,
        right: '0vw',
        textStyle: {
          color: '#00B2FD',
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      grid: {
        left: 10,
        right: 10,
        top: '30%',
        height: '40%',
      },
      xAxis: {
        boundaryGap: false,
        data: this.xAxisData,
        axisLine: {
            show: true,
            lineStyle: {
                color: '#078CD6',
            },
        },
       
      },
      yAxis: {
        min: 0,
        max: 25,
        interval: 5,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: ['#078CD6'],
          },
        },
        boundaryGap: false,
      },
      series: {
        symbol: 'none' /* 去掉小圆点 */,
        name: '当前流量',
        type: 'line',
        data: this.yAxisData /* ,smooth:true//显示为平滑的曲线 */,
        lineStyle: {
          color: '#00B2FD',
        },
        areaStyle: {
          color:'#00B2FD',
          opacity: 0.3,
          origin: "start"
        },
      },
    };

    const heartMap = document.getElementById(`breathMap${this.props.id}`);

    if (heartMap) {
      this.totalFlowRate = echarts.init(heartMap);
      this.totalFlowRate.setOption(this.totalFlowRateOption);
    }
  }

  componentDidUpdate() {
    let {breath,time} = this.props.breathHeart;
    this.totalFlowRateOption.title.text = `${breath === 0 ? '--' : breath}BPM`;

    if(time && time>new Date().getTime()-1000){
      this.yAxisData.push(breath);

      if (this.yAxisData.length > 1000) {
        this.yAxisData.shift();
      }
      this.totalFlowRate.setOption(this.totalFlowRateOption);
    }
  }

  render() {
    return (
      <>
        <div
          id={`breathMap${this.props.id}`}
          style={{ height: '15vh', backgroundColor: 'rgba(255, 255, 255, 0)' }}
        ></div>
      </>
    );
  }
}
