/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import {phoneValidate} from 'utils/validate'
import {login} from 'service/service'
import Style from './Login.module.css'
import * as CryptoJS from 'crypto-js'
import qs from 'query-string'

// 加密
// function Encrypt (text,key,iv) {
//   return CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
//     iv: CryptoJS.enc.Utf8.parse(iv),
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7
//   }).toString()
// }

// 解密
function Decrypt (text,key,iv) {
  let decrypted = CryptoJS.AES.decrypt(text, CryptoJS.enc.Utf8.parse(key), {
    iv: CryptoJS.enc.Utf8.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}

function getKeys(){
  const keys = new Map();
  keys.set("15605170163","Dzes4ZX3V7z1Gtcw0jYqokNvetEXGJxC")
  keys.set("18030453541","MBgsFatNPQxPKwCU5Xm7pkrQgZHGEoqV")
  return keys;
}

export default class Login extends Component {
  state = {
    ssoFlag:false,
    errorMsg:'',
    phone:localStorage.getItem("phone"),
    password:''
  }

  handleSubmit = async () => {

    if(this.state.phone===undefined || this.state.phone===""){
      this.setState({errorMsg:'请输入用户名'})
      return;
    }

    const err = phoneValidate(this.state.phone);
    if(err){
      this.setState({errorMsg:err})
      return;
    }

    if(this.state.password===undefined || this.state.password===""){
      this.setState({errorMsg:'请输入密码'})
      return;
    }

    login({phone:this.state.phone,password:this.state.password}).then(res=>{
      if(res.success){
        if(res.data.name===""){
          this.setState({errorMsg:"账户或密码错误"});
          return;
        }
        localStorage.setItem('phone',this.state.phone);
        localStorage.setItem('account_name',res.data.name);
        localStorage.setItem('token_center',res.data.token_center);
        localStorage.setItem('token_expires',(new Date().getTime()+1000*60*60*24)+"");

        localStorage.setItem('user_name',res.data.user.user_name);
        localStorage.setItem('logo_url',res.data.user.logo_url);
        localStorage.setItem('user_name',res.data.user.user_name);

        this.props.history.push('/monitor/index')
      }else{
        this.setState({errorMsg:res.msg})
      }
    });
  }

  componentDidMount() {
    const that = this;
    const keys = getKeys();
    const iv = '83023628'
    //
    const {phone,signature,timestamp,encryptedData} = qs.parse(this.props.history.location.search?.replace(/^\?/, ''));
    console.log(phone,signature,timestamp,encryptedData)
    if (phone && signature && timestamp && encryptedData){
      try {
        this.setState({
          ssoFlag:true
        })
        let key = keys.get(phone); //以后通过 phone、timestamp、signature 到服务器请求key signature=md5(phone+timestamp+key)
        console.log("key",key)
        if (key === undefined) key = "MMgsFatNPQxPKwCU5Xm7pkrQgZHGEoqA";
        console.log("key",key)
        let encryptedDataD =  CryptoJS.enc.Base64.parse(encryptedData).toString(CryptoJS.enc.Utf8);
        console.log(encryptedDataD)
        console.log(Decrypt(encryptedDataD,key,iv))

        let data = JSON.parse(Decrypt(encryptedDataD,key,iv));
        console.log(data)
        this.setState({...data})
        setTimeout(()=>{
          console.log(this.state)
          that.handleSubmit();
        },100)
      }
      catch (e) {
        console.log("单点登录失败",e)
        console.log("111111")
        this.setState({password: "c79681491103b5d0",phone: phone})
        setTimeout(()=>{
          console.log(this.state)
          that.handleSubmit();
        },100)

      }
    }

    // let phone = '18030453541';
    // let password = '123456';
    // let key = "MBgsFatNPQxPKwCU5Xm7pkrQgZHGEoqV"
    // let timestamp = Math.round(new Date().getTime()/1000); //秒
    // let signature = CryptoJS.MD5(JSON.stringify({phone,timestamp,key})).toString().toUpperCase()
    // let encryptedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(Encrypt(JSON.stringify({phone,password}),key,iv)));
    // console.log("phone="+phone+"&timestamp="+timestamp+"&signature="+signature+"&encryptedData="+encodeURIComponent(encryptedData))

  }
x

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return
    }
  }



  render() {
    const {ssoFlag,phone,password,errorMsg} = this.state;

    return (
      <div className={Style.login}
      style={{
        backgroundColor:"rgb(17,15,107)",
        background: "url(/images/bgdl.jpg) no-repeat",
        backgroundSize: "100% 100%"
    }}
      >


        <div className={Style.login_content} style={{display:ssoFlag?"none":""}}>
          <div className={Style.login_content_logo}>
            {window.location.pathname==="/mm"?<img src={"/images/dl01-mm.png"} alt="" border="0"  style={{width:"100%"}}/>:(<img src={"/images/dl01.png"} alt="" border="0"  style={{width:"100%"}}/>)}
            </div>
          <div className={Style.login_content_form} >
            <img src={"/images/dl02.png"} alt=""  style={{width:"100%"}}/>

            <div className={Style.input}>
              <img src={"/images/dl03.png"} alt="" style={{width:"100%"}}/>
              <div className={Style.input1}><input type="text" placeholder="请输入用户名" value={phone} onChange={e => this.setState({phone:e.target.value})}></input></div>
              <div className={Style.input2}><input type="password" placeholder="请输入密码" value={password}  onChange={e => this.setState({password:e.target.value})}></input></div>
            </div>
            <div className={Style.desc}>请使用系统已授权的用户名和密码登录</div>
            <div className={Style.err}>{errorMsg}</div>
            <div className={Style.btn}>
              <div className={Style.fly_mid}></div>
              <a className={Style.reset} href="#" onClick={(e)=>{
                e.preventDefault();
                this.setState({phone: "",password: ""})}}>重置</a>
              <div className={Style.fly_mid} style={{marginRight:'1rem'}}></div>
              <div className={Style.fly_mid}></div>
              <a className={Style.submit} href="#" onClick={(e)=>{
                e.preventDefault()
                this.handleSubmit();
              }}>登录</a>
              <div className="fly_mid"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
