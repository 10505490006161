import axios from 'axios';
import BaseConfig from 'config/base.config'
// request 封装
const instance = axios.create({
    baseURL: BaseConfig.ApiUrl,
    headers: { 'Content-Type': 'application/json' },
    timeout: 20000+Math.random()
})

//添加拦截
instance.interceptors.request.use(config => {
    // todo 请求拦截
    // console.log('Http请求:',config);
    if (localStorage.getItem("token_center")) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers['token-center'] = localStorage.getItem("token_center");
    }
    // console.log(instance.defaults.timeout)
    return config
},error => {
    // return Promise.reject(error);
    return {success:false,data:[]}
})

instance.interceptors.response.use(res => {
    // console.log('Http返回:',res.data);
    if(res.data.success===false && res.data.msg==='请先登录'){
        window.location='/#/user/login'
    }
    return res.data
},error => {
    // console.log("error",error)
    return {success:false,data:[]}
    // return Promise.reject(error);
})

export default instance;