import React from 'react';
import Style from './Map2D.module.css'

export default class Map2DYuwei extends React.Component {
    w=0;
    h=0;
    mapWidth=0;
    mapHeight=0;
    roomW ;
    roomH;
    clearMapTimer = undefined;
    currPoint = new Map();//当前点
    canvas;
    ctx;
    frame = 45;

    constructor(props) {
        super(props);
        this.mapWidth = props.mapWidth;
        this.mapHeight = props.mapHeight;
        this.w = this.mapWidth;
        this.h = this.mapHeight;
        this.roomW = props.roomInfo.width / 100;
        this.roomH = props.roomInfo.length / 100;

    }

    renderLoop = () => {
        this.drawPoint()
        window.requestAnimationFrame(this.renderLoop)
    }

    drawPoint(){
        this.ctx.clearRect(0, 0, 3000, 3000)
        for (let key of this.currPoint.keys()) {
            try {
                if (this.currPoint.get(key).time<new Date().getTime()-5000){//大于5秒的点不绘制了
                    continue;
                }

                if (this.currPoint.get(key).time<new Date().getTime()-1000){//大于1秒的点，如果离房间边缘进，不绘制了
                    let roomSpace = 0.1;
                    if (this.currPoint.get(key).x<roomSpace || this.currPoint.get(key).y<roomSpace) continue;
                    if (this.currPoint.get(key).x>this.roomW-roomSpace || this.currPoint.get(key).y>this.roomH-roomSpace) continue;
                }

                if (this.currPoint.get(key).frame<this.frame){
                    this.currPoint.get(key).x += (this.currPoint.get(key)?.nextX-this.currPoint.get(key)?.lastX)/this.frame;
                    this.currPoint.get(key).y += (this.currPoint.get(key)?.nextY-this.currPoint.get(key)?.lastY)/this.frame;
                    this.currPoint.get(key).frame++;
                }else{
                    this.currPoint.get(key).x = this.currPoint.get(key)?.nextX;
                    this.currPoint.get(key).y = this.currPoint.get(key)?.nextY;
                }

                let x = ((this.currPoint.get(key).x * this.w) / this.roomW).toFixed(5)
                let y = (this.h - (this.currPoint.get(key).y * this.h) / this.roomH).toFixed(5);

                this.ctx.beginPath()
                this.ctx.arc(x, y, 5, 0, Math.PI * 2)
                this.ctx.fillStyle = "#00"+Number(this.currPoint.get(key).tid).toString(16)+"00"
                // this.ctx.fillStyle = '#008B00'
                this.ctx.strokeStyle = 'rgba(1,1,1,0)'
                this.ctx.fill()
                this.ctx.stroke()
            }catch (e){
                console.log(e)
            }
        }
    }

    clearHistoryMap = ()=>{
        this.clearMapTimer = setInterval(() => {
            for (let key of this.currPoint.keys()) {
                // console.log(key,this.currPoint.get(key).time-new Date().getTime())
                if (this.currPoint.get(key).time<new Date().getTime()-2500) {
                    // console.log(key,"已清除")
                    this.currPoint.delete(key);//  清除2.5s到3s之间没更新的点
                }
            }
        }, 500)

    }

    componentDidMount() {
        this.canvas = document.getElementById("pointCanvas");
        this.ctx = this.canvas.getContext('2d');
        this.renderLoop();

        // this.clearHistoryMap();

    }

    shouldComponentUpdate(nextProps) {
        if(nextProps.pointTime!==this.props.pointTime || nextProps.alertTime!==this.props.alertTime){
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.props.points && this.props.points.device_sn && this.props.points.points && Array.isArray(this.props.points.points)) {
            this.props.points.points.forEach(p => {
                let key = `${this.props.points.device_sn}_${p.tid}`;
                let currX = p.x;
                let currY = p.y;
                if (this.currPoint.get(key) && Math.abs(p.x-this.currPoint.get(key).nextX)<0.7 && Math.abs(p.y-this.currPoint.get(key).nextY)<0.7) {
                    currX = this.currPoint.get(key).x;
                    currY = this.currPoint.get(key).y;
                }
                this.currPoint.set(key, {
                    tid:p.tid,
                    x:currX,
                    y:currY,
                    lastX:currX,
                    lastY:currY,
                    nextX:p.x,
                    nextY:p.y,
                    frame:0,
                    time: this.props.pointTime
                });
            });
        }
    }

    componentWillUnmount() {
        if(this.clearMapTimer) clearInterval(this.clearMapTimer)
    }


    render() {
        let {startPoint} = this.props;
        console.log("startPoint",startPoint)

        // const color = ['#008B00','#4662D9','#2ABDA3','#E1A02F','#C11462','#F064E2','#1F4AE2','#F064E2','#2FA161','#C11462'];
        let flat_image_url = "/images/floor.jpg";

        if (this.props.roomInfo && this.props.roomInfo.flat_image_url && this.props.roomInfo.flat_image_url !== "") {
            flat_image_url = this.props.roomInfo.flat_image_url;
        }

        // console.log(this.props.alertList)
        const alertList = this.props.alertList.filter(res => {
            // console.log(res)
            if (res.alarm_process === 1 && res.last_point !== "" && res.last_point !== "[]") {
                let lastPoint = JSON.parse(res.last_point);
                if (lastPoint.x > 0 && lastPoint.x < this.roomW && lastPoint.y > 0 && lastPoint.y < this.roomH) {
                    return res;
                }
            }
            return false;
        });
        return (
            <>
                <div
                    id={"roomBg"}
                    style={{
                        margin: '0 auto',
                        position: "relative",
                        width: this.mapWidth,
                        height: this.mapHeight,
                        marginBottom: '20px',
                        // backgroundImage: `url(${flat_image_url})`,
                        backgroundRepeat: 'no-repeat',
                        // backgroundImage: `url('/images/playbg.jpg')`,
                        backgroundSize: '100% 100%',
                        backgroundPosition: 'center center',
                        overflow: 'none',
                        // border: '1px solid green',
                    }}
                >
                    <img id={"roomPic"} alt={""} src={flat_image_url} style={{
                        position:'absolute',left:0,right:0,top:0,bottom:0,margin:'auto',
                        maxWidth:this.mapWidth,
                        maxHeight:this.mapHeight,
                    }}
                         onLoad={e => {
                             if (e.target.width<this.mapWidth && e.target.height<this.mapHeight){
                                if(e.target.width/this.mapWidth>e.target.height/this.mapHeight){
                                    e.target.width = this.mapWidth;
                                }else{
                                    e.target.height = this.mapHeight;
                                }
                             }
                             document.getElementById("alertPoints").style.display="";
                             if (e.target.width/this.mapWidth<0.8 || e.target.height/this.mapHeight<0.8) {
                                 e.target.height -=16 ;
                                 document.getElementById("roomBg").style.backgroundImage="url('/images/playbg.jpg')";
                             }
                             this.w = e.target.width;
                             this.h = e.target.height;
                             this.forceUpdate();
                         }}
                    />
                    <div id={"alertPoints"} style={{
                        position:"absolute",display:"none",left:(this.mapWidth-this.w)/2,top:(this.mapHeight-this.h)/2,
                    }}>
                    {alertList?.map(res => {
                        let lastPoint = JSON.parse(res.last_point);
                        return <div key={res.alert_id}
                                    style={{
                                        position: "absolute",
                                        width: "40px",
                                        left: `${((lastPoint.x * this.w) / this.roomW - 20).toFixed(5)}px`,
                                        top: `${(this.h - (lastPoint.y * this.h) / this.roomH - 20).toFixed(5)}px`
                                        }}>
                                <img className={Style.shuai} src="/images/icon/i01-3.png" alt=""
                                     title={`${((lastPoint.x * this.w) / this.roomW).toFixed(5)}px,${(this.h - (lastPoint.y * this.h) / this.roomH).toFixed(5)}px`}
                                />
                            </div>
                        })
                    }


                        {startPoint.x && (
                            <div key={"startPoint"}
                                 style={{
                                     position: "absolute",
                                     width: "40px",
                                     left: `${((startPoint.x * this.w) / this.roomW - 20).toFixed(5)}px`,
                                     top: `${(this.h - (startPoint.y * this.h) / this.roomH - 20).toFixed(5)}px`
                                 }}>
                                <img className={Style.shuai} src="/images/icon/i05-1.png" alt=""
                                     title={`${((startPoint.x * this.w) / this.roomW).toFixed(5)}px,${(this.h - (startPoint.y * this.h) / this.roomH).toFixed(5)}px`}
                                />
                            </div>
                        )}


                    </div>
                    <canvas id={"pointCanvas"} width={this.w} height={this.h}
                            style={{
                                position:"absolute",left:(this.mapWidth-this.w)/2,top:(this.mapHeight-this.h)/2,
                            }}>
                        Your browser does not support the canvas element.
                    </canvas>
                </div>
            </>
        );
    }
}
