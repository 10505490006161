import React, { Component } from 'react'
import store from 'store/index'
import {alertMap} from 'utils/common'
import Style from './WarnList.module.css'

export default class WarnList extends Component {
    constructor(props){
        super(props)
        this.state = store.getState();
    }
    unSubscribe = ()=>{}
    handleStoreChange = ()=>{
        let {roomList} = store.getState();
        const warnList = roomList?.filter(res=>res.room_status===44);
        this.setState({warnList});
    }
    componentDidMount(){
        this.unSubscribe =  store.subscribe(this.handleStoreChange);
    }
    componentWillUnmount(){
        this.unSubscribe();
    }

    render() {
        let {warnList} = this.state;
        return (
            <>
                {warnList && warnList.length>0&&(
                    <div className={Style.main} style={{background: "url(/images/bgwarn.png) no-repeat",backgroundSize: "100% 100%"}}>
                        <div className={Style.marquee}>
                            <div className={warnList.length>3?Style.divpao:""}>
                            {warnList.map((res,idx)=>{  
                                return <span className={Style.span} key={`warnlist-${idx}`}>
                                <span className={Style.span} >{idx>0?"|":""}</span>
                                <span className={Style.span} >{res.room_name} {alertMap(res.alert_type).title}</span>
                                </span>})}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}
