const phoneReg = /^1[123456789]\d{9}$/

/**
 *  手机号码自定义验证
 * @param phoneValue
 * @returns {*}
 */
export function phoneValidate (phoneValue) {
  if (phoneValue) {
    const regOk = phoneReg.test(phoneValue)
    if (regOk) {
      return false
    } else {
      return '输入的手机号码的格式不正确'
    }
  } else {
    return '请输入手机号码'
  }
}

/**
 *  验证手机验证码的格式
 * @param codeValue
 * @returns {*}
 */
export function phoneCodeValidate (codeValue) {
  if (codeValue) {
    if (codeValue.length === 6) {
      return false
    } else {
      return '请输入六位手机验证码'
    }
  } else {
    return '请输入手机验证码'
  }
}

/**
 * 图形验证码的验证规则
 * @param verifyCode
 * @returns {*}
 */
export function verifyCodeValidate (verifyCode) {
  if (verifyCode) {
    if (verifyCode.length === 3) {
      return false
    } else {
      return '请输入三位图形验证码'
    }
  } else {
    return '请输入图形验证码'
  }
}
