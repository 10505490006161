import React, { Component } from 'react'
import AlertList from './AlertList/AlertList'
import ShowTime from '../Index/ShowTime/ShowTime'
import AlertStatus from './AlertStatus/AlertStatus'
import Map2D from './Map2D/Map2D'
import BreathHeart from './BreathHeart/BreathHeart'
import HealthReport from './HealthReport/HealthReport'
import AudioSwitch from './AudioSwitch/AudioSwitch'
import {getRoomInfo,outLogin} from 'service/service'
import store from 'store/index'
import AudioConfirm from 'components/Monitor/Room/AudioConfirm/AudioConfirm'
import './Room.css'
import BreathHeartSimulation from "./BreathHeart/BreathHeartSimulation";
import BreathHeartSimulation1018 from "./BreathHeart/BreathHeartSimulation1018";
import {Tooltip} from "antd";

export default class Room extends Component {
    constructor(props) {
        super(props);
        this.map2dRef = React.createRef();

        this.state = {
            account_name : localStorage.getItem("account_name"),
            user_name : localStorage.getItem("user_name"),

            healthReportShow: false,
            healthDeviceCode:"",

            roomInitFlag: false,

            breathData: 0, // 呼吸心率
            heartData: 0, // 呼吸心率

            mapWidth : 0,
            mapHeight : 0,

            bedList : undefined,
            breathHeartSimulation:{},
            breathTime : 0,
            pointTime:0,
            ...store.getState()
        };
    }

    breath = 0;
    heart = 0;


    handleResize = () =>{
        this.setState({
            mapWidth: this.map2dRef.current.offsetWidth,
            mapHeight: this.map2dRef.current.offsetHeight,
        });
      }

    initResize=()=>{
        // 获取图片大小
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }



    hotList = [];
    posListFun = (data) => {
        const { type, point_list,device_sn } = data
        if (type !== 'pos_list') return;

        // console.log(point_list)
        let points = [];
        for (let tid in point_list) {
            points.push(point_list[tid][0])
        }
        // console.log(points)
        if (points.length>0){
            this.hotList.push(new Date().getTime());
            this.hotList = this.hotList.slice(-100);
            this.setState({
                pointsData: {points,device_sn},
                hotList: this.hotList,
                pointTime: new Date().getTime() })
        }
    };

    tracePointFun = (data) => {
        const { type, point, device_sn } = data
        if (type !== 'trace_point') return;

        // console.log(point)
        if (point && point.length>0){
            this.hotList.push(new Date().getTime());
            this.hotList = this.hotList.slice(-100);
            this.setState({
                pointsData: {points:point,device_sn},
                hotList: this.hotList,
                pointTime: new Date().getTime()
            })
        }
    };

    heartFun = (idx)=>{
        this.socket[idx].lastHeartTime = new Date().getTime()
    }


    // breathHeartBedData = [];
    lastBreathTime = 0;
    breathFun = (data,no,device_src) => {
        let {type,breath,breath_wave,heart,heart_wave,device_sn} = data;
        if (type !== 'breath') return;

        if (breath_wave >1) breath_wave = (1-Math.random()/10).toFixed(2);
        if (breath_wave <-1) breath_wave = (-1+Math.random()/10).toFixed(2);

        if (heart_wave >1) heart_wave = (1-Math.random()/10).toFixed(2);
        if (heart_wave <-1) heart_wave = (-1+Math.random()/10).toFixed(2);


        breath = Number(breath)
        breath_wave = Number(breath_wave)
        heart = Number(heart)
        heart_wave = Number(heart_wave)

        const bedList = this.state.bedList?.map(res=>{
            if(res.device_sn === device_sn){
                res.data = {breath,breath_wave,heart,heart_wave,device_src,device_sn,time:new Date().getTime()}
            }
            return res;
        });

        if (new Date().getTime()-this.lastBreathTime>1){
            this.lastBreathTime = new Date().getTime();
            this.setState({bedList,breathTime:new Date().getTime});
        }
      };

    lastSimulationTime = 0;
    breathHeartSimulationFun = (data)=>{
        let {breath,heart,device_sn} = data;
        this.lastSimulationTime = new Date().getTime();
        this.setState({breathHeartSimulation:{breath,heart,device_sn,time:this.lastSimulationTime}})
    }

    reconnect(url, deviceType, idx) {
        const that = this;
        if (this.socket[idx].lockReconnect) {
            return;
        }
        this.socket[idx].lockReconnect = true;
        // 没连接上会一直重连，设置延迟避免请求过多
        if (this.reconnectTimeoutHandle) clearTimeout(this.reconnectTimeoutHandle);
        this.reconnectTimeoutHandle = setTimeout(function () {
            that.socket[idx].socket.close();//先强制关闭上一个连接。
            that.initWebSocket(url, deviceType, idx);}, 5000);
    }

    checkHeartTimeHandle = undefined;
    checkHeartTime = ()=>{
        this.socket?.forEach((s) => {
            if (s.lastHeartTime<new Date().getTime()-6000){
                this.reconnect(s.url,s.deviceType,s.idx)
            }
        });
        this.checkHeartTimeHandle = setTimeout(this.checkHeartTime,2000);
    }


    webSocketOnopen = (e, url, deviceType, idx) => {
        console.log('WebSocket连接成功', url, deviceType, idx);
    };
    webSocketOnError = (e, url, deviceType, idx) => {
        console.log('WebSocket连接错误', url, deviceType, idx);
        // this.reconnect(url, deviceType, idx); // 重连
    };
    webSocketOnClose = (e, url, deviceType, idx) => {
        console.log('WebSocket连接关闭', url, deviceType, idx);
        // this.reconnect(url, deviceType, idx); // 重连
    };
    webSocketOnMessage = (e, deviceType, idx) => {
        if (e.data === null || JSON.parse(e.data).type === undefined || JSON.parse(e.data).type === null ) {
          console.log('WebSocket 数据格式错误',e, deviceType, idx);
          return;
        }
        const data = JSON.parse(e.data);

        // console.log(deviceType,data)
        switch (deviceType) {
            case 'mm':
                if (data.type === "heart") this.heartFun(idx);
                //todo 两个都开发，只会发一个数据 20220117
                if (data.type === "pos_list") this.posListFun(data); //苗米轨迹
                if (data.type === "trace_point") this.tracePointFun(data); //南航轨迹
                if(data.type === "breath") this.breathFun(data,idx,'mm');
                if(data.type === "breath_heart_simulation") this.breathHeartSimulationFun(data);
                break;
            case 'breathOld':
                if(data.type === "breath") this.breathFun(data,idx,'old');
                break;
            case 'breathOther':
                if(data.type === "breath") this.breathFun(data,idx,'other');
                break;
            default:
                break;
        }
      };

    // 初始化WebSocket
    socket = [];
    initWebSocket = (url, deviceType, idx) => {
        const that = this;
        this.socket[idx] = {
            url: url,
            deviceType:deviceType,
            idx:idx,
            socket: new WebSocket(url),
            lockReconnect: false,
            lastHeartTime: new Date().getTime(),
        };
        this.socket[idx].socket.onopen = function (e) {
            that.webSocketOnopen(e, url, deviceType, idx);
        };
        this.socket[idx].socket.onerror = function (e) {
            that.webSocketOnError(e, url, deviceType, idx);
        };
        this.socket[idx].socket.onclose = function (e) {
            that.webSocketOnClose(e, url, deviceType, idx);
        };
        this.socket[idx].socket.onmessage = function (e) {
            that.webSocketOnMessage(e, deviceType, idx);
        };
    }

    socketIndex = 0;
    initRoom=()=>{
        getRoomInfo({
            room_id:localStorage.getItem("curr_room_id"),
            api_mode:localStorage.getItem("curr_api_mode"),
            token:localStorage.getItem("curr_token")
        }).then(res=>{
            if(res.success===true && res.code===0){
                this.setState({roomInitFlag:true,roomInfo:res.data});
                console.log(res.data);

                localStorage.setItem("curr_room_name",res.data.room_name)

                // 苗米Socket
                if (res.data.web_socket_ip !== null && res.data.web_socket_port != null) {
                    this.initWebSocket(`ws://${res.data.web_socket_ip}:${res.data.web_socket_port}/${res.data.room_id}`,'mm',this.socketIndex++);
                }

                let bedList = [];
                if(res.data.bed_list && res.data.bed_list.length>0){
                    for (const bed of res.data.bed_list) {
                        bedList.push({no:bed.no,device_sn:bed.device_sn,name:bed.note,data:{breath:0,breath_wave:0,heart:0,heart_wave:0,device_src:'mm',time:0}});

                        //苗米1.0设备，每个设备一个WebSocket
                        if(localStorage.getItem("curr_api_mode")==="api.wieyes.cn" || localStorage.getItem("curr_api_mode")==="api.test.wieyes.cn"){
                            this.initWebSocket(`ws://${bed.web_socket_ip}:${bed.web_socket_port}/${bed.device_sn}`,'breathOld',this.socketIndex++);
                        }
                    }
                }

                // 友康逻辑1.5 南虹设备
                if(res.data.other_bed_list!==undefined && res.data.other_bed_list.length>0){
                    for (const bed of res.data.other_bed_list) {
                        bedList.push({no:bed.no,device_sn:bed.device_sn,name:bed.note,data:{breath:0,breath_wave:0,heart:0,heart_wave:0,device_src:'other',time:0}})
                        this.initWebSocket(`ws://${bed.web_socket_ip}:${bed.web_socket_port}/${bed.device_sn}`,'breathOther',this.socketIndex++);
                    }
                }
                this.setState({bedList});
            }
        });
    }

    componentDidMount(){
        // const that = this;
        this.unSubscribe = store.subscribe(()=>{
            this.setState(store.getState());
        })
        this.initResize();//初始化图片大小
        this.initRoom();//初始化房间信息
        this.checkHeartTime();

        // //TODO 测试
        // setInterval(() => {
        //     let breath = Math.round(14+5*Math.random());
        //     let heart = Math.round(70+15*Math.random());
        //     let device_sn = "11111";
        //     this.lastSimulationTime = new Date().getTime();
        //     this.setState({breathHeartSimulation:{breath,heart,device_sn,time:this.lastSimulationTime}})
        // }, 1000);

    }
    componentWillUnmount(){
        this.setState = () => false;
        store.dispatch({type:"roomAlertList",value: []})
        store.dispatch({type:"roomAlertCurr",value: { title: '室内暂无报警',color:"#16ef08",pic: "/images/R01.svg" }})

        this.unSubscribe();
        if (this.checkHeartTimeHandle) clearTimeout(this.checkHeartTimeHandle);
        window.removeEventListener('resize', this.handleResize);


        this.socket?.forEach((s) => {
            console.log(s.socket.url, 'closed');
            s.socket.close();
        });
        this.socket.length=0;
    }


    render() {
        const {account_name,mapWidth,mapHeight,pointsData,pointTime,roomInitFlag,roomInfo,healthDeviceCode,healthReportShow,bedList,breathHeartSimulation,roomAlertList,roomAlertTime,roomAlertCurr} = this.state;
        return (
            <>
            <AudioConfirm />
            <div className="roomMain" style={{
                    backgroundColor:"rgb(17,15,107)",
                    background: "url(/images/bg.jpg) no-repeat",backgroundSize: "100% 100%"
                }}>
                <div className="head">
                <div className={"topn"}>{localStorage.getItem("logo_url") ===""?<img src={"/images/topn.png?v=20220124"} alt="" border="0"  style={{width:"100%"}}/>:<img src={localStorage.getItem("logo_url")} alt="" border="0"  style={{width:"100%"}}/>}</div>
                    <div className={"account"}>
                        <img src={"/images/topP.png"} alt={""} style={{width:"1rem"}} /> {account_name}</div>
                    <div className={"qback"}>
                    <div>
                        <Tooltip color={"#141E6C"} placement="bottom" title={"返回房间列表"}>
                            <img src={"/images/back.png"} alt="" border="0" onClick={()=>{
                                this.props.history.push('/monitor/index')
                            }} />
                        </Tooltip>
                    </div>
                    <div className="fly_mid"></div>
                        <div>
                            <Tooltip color={"#141E6C"} placement="bottom" title={"退出登录"}>
                                <img  src={"/images/Q.png"} alt="" border="0" onClick={()=>{
                                    outLogin()
                                    this.props.history.push('/user/login')
                                }}/>
                            </Tooltip>
                        </div>
                    </div>

                    <div className={"user"}>
                        {localStorage.getItem("phone") === "18116021281"?"":localStorage.getItem("curr_room_name")}
                    </div>

                    <div className={"date"}><ShowTime></ShowTime></div>
                    <div className={"alert_shan"}>
                        <div className="image"><img src={roomAlertCurr.pic} alt="" border="0" style={{width:"100%"}}/></div>
                        <div className="txt" style={{color:roomAlertCurr.color}}>{roomAlertCurr.title}</div>
                    </div>
                    <div className={"voice"}>
                        <AudioSwitch />
                    </div>
                </div>
                <div className="content">
                    <div className="row1"><AlertStatus /></div>
                    <div className="row2">
                        <div ref={this.map2dRef} className="col1">
                            {roomInitFlag && (<Map2D
                                mapWidth={mapWidth}
                                mapHeight={mapHeight}
                                points={pointsData}
                                pointTime = {pointTime}
                                alertList = {roomAlertList}
                                alertTime = {roomAlertTime}
                                roomInfo={roomInfo}
                                ></Map2D>)}
                            </div>
                        <div className="col2">
                            <AlertList></AlertList>
                        </div>
                    </div>
                    <div className="row3">
                        {/*<BreathHeartSimulation1018 hotList={this.hotList} />*/}
                        {/*<BreathHeartSimulation breathHeartSimulation={breathHeartSimulation} />*/}

                        {/*定制开发，未来会删除1018这个逻辑*/}
                        {localStorage.getItem("curr_room_id")==="1018" && (<BreathHeartSimulation1018 hotList={this.hotList} />)}
                        {localStorage.getItem("curr_room_id")!=="1018" && this.lastSimulationTime<new Date().getTime()-5000 && (<BreathHeart bedList={bedList} showReport={(device_sn)=>{this.setState({healthReportShow: true,healthDeviceCode:device_sn})}} />)}
                        {localStorage.getItem("curr_room_id")!=="1018" && this.lastSimulationTime>=new Date().getTime()-5000 && (<BreathHeartSimulation breathHeartSimulation={breathHeartSimulation} />)}


                    </div>
                </div>


                {healthReportShow===true && (<HealthReport device_sn={healthDeviceCode} onClose={()=>{
                        this.setState({healthReportShow: false})
                    }}>
                </HealthReport>) }
            </div>
            </>
        )
    }
}
