import React, {Component} from 'react';
import Style from './Variance.module.css'
import VarianceChart from "./Variance/VarianceChart";
import qs from 'query-string'

class Variance extends Component {
    state={
        vData:[],
    }
    socket = undefined

    lastTime=new Date().getTime();
    componentDidMount() {
        const {id} = qs.parse(this.props.history.location.search?.replace(/^\?/, ''));
        this.room_id = id;

        let that = this;
        this.socket = new WebSocket("ws://106.15.183.112:8080/websocket/ws-variance-client");
        this.socket.onopen = function (e) {
            console.log("socket onopen",e)
        };
        this.socket.onerror = function (e) {
            console.log("socket onerror",e)
        };
        this.socket.onclose = function (e) {
            console.log("socket onclose",e)
        };
        this.socket.onmessage = function (e) {
            that.setState({
                vData:e.data
            })

        };
    }


    componentWillUnmount() {
        clearTimeout(this.timeHandle)
    }
    render() {
        let {vData} = this.state;
        return (
            <div className={Style.main}>

                <div className={Style.flexRow}>
                    <div className={Style.variance}><VarianceChart vData={vData} ></VarianceChart></div>
                </div>

            </div>
        );
    }
}

export default Variance;
