import React, { Component } from 'react'

export default class ShowTime extends Component {
    state = {
        dateDay: '',
        dateWeek: '',
        dateTime: '',
        liPic:'/images/liday.png'
      };

      days = ['日', '一', '二', '三', '四', '五', '六'];
      getDateTime = () => {
        const currentDT = new Date();
        // const y = currentDT.getFullYear(); // 四位整数表示的年份
        const m = currentDT.getMonth() + 1;
        const date = currentDT.getDate();
        const day = currentDT.getDay();
        const hs = currentDT.getHours();
        const ms = currentDT.getMinutes();
        const ss = currentDT.getSeconds();
        this.setState({
          liPic: (hs<6 || hs >=18)?'/images/linight.png':'/images/liday.png',
          dateDay: `${m < 10 ? `0${m}` : m}月${date < 10 ? `0${date}` : date}日`,
          dateWeek: `星期${this.days[day]}`,
          dateTime: `${hs < 10 ? `0${hs}` : hs}:${ms < 10 ? `0${ms}` : ms}:${ss < 10 ? `0${ss}` : ss}`,
        });
        this.timeoutHandle = setTimeout(this.getDateTime, 1000);
      };

      componentDidMount() {
          this.getDateTime();
      }

      componentWillUnmount() {
        if (this.timeoutHandle) clearTimeout(this.timeoutHandle);
        this.setState = () => false;
      }

    render() {
        return (
        <>
            <div className={'lipic'}><img alt="" src={this.state.liPic}  style={{width:"100%"}}></img></div>
            <div className={'day'}>{this.state.dateDay}</div>
            <div className={'week'}>{this.state.dateWeek}</div>
            <div className={'time'}>{this.state.dateTime}</div>
        </>
        )
    }
}
