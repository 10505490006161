import React from 'react';
import * as echarts from 'echarts';

export default class DebugRangeBinTestChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    dataMap = new Map();
    height =1;
    initChart = ()=>{

        for (let i=0;i<64;i++){
            let temp = [];
            for (let j=0;j<100;j++){
                temp.push(i*this.height);
            }
            this.dataMap.set(i,temp)
        }

        let dataIndex=0;
        this.option = {
            animation: false,
            title: {
                text: '热力差值'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: []
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
            },
            yAxis: {
                min: 0,
                max: 63,
                interval: 5,
                type: 'value',
                show:false
            },
            series: [
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},
                {type: 'line',smooth:false,symbol: 'none',data: this.dataMap.get(dataIndex++)},

            ]
        };




        console.log(this.option)
        const heartMap = document.getElementById(`testRangeChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap);
            this.eChart.setOption(this.option);
        }
    }



    componentDidMount() {
        this.initChart();
    }

    componentWillUnmount() {
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }

    componentDidUpdate() {
        let {range_bin_test} = this.props;
        if(range_bin_test){
            let data = range_bin_test
            for (let i=0;i<data.length;i++){
                // console.log(this.dataMap.get(i))
                let temp = this.dataMap.get(i);
                temp.push(i*this.height+data[i]);
                temp.shift()
                this.dataMap.set(i,temp)
            }
            // console.log(this.dataMap)
            this.eChart.setOption(this.option);
        }
    }

    render() {
        return (
            <>
                <div
                    id={`testRangeChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%'}}
                ></div>
            </>
        );
    }
}
