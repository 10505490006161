import React, { Component } from 'react'
import MonitorPeopleCounting from 'components/Monitor/PeopleCounting/PeopleCounting'

export default class PeopleCounting extends Component {
    render() {
        return (
            <MonitorPeopleCounting history={this.props.history}></MonitorPeopleCounting>
        )
    }
}
