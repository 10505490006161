import React, { Component } from 'react'
import { Modal, Button} from 'antd';

import {roomAlarmList, alarmProcess, alarmProcessMisreport} from 'service/service'
import {alertMap} from 'utils/common'
import store from 'store/index'
import Style from './style.module.css'


export default class AlertList extends Component {
    constructor(props){
        super(props)
        this.state = {
            initFlag: false,
            scanTime:0,
            clientX:0,
            clientY:0,
            modalMap: new Map(),
            ...store.getState()
        };
    }

    scanAlertListHandle = undefined;
    scanAlertList = ()=>{
        clearTimeout(this.scanAlertListHandle);
        roomAlarmList({
            room_id:localStorage.getItem("curr_room_id"),
            api_mode:localStorage.getItem("curr_api_mode"),
            token:localStorage.getItem("curr_token")
        }).then(res=>{
            if(res.data && res.data){
                let audioCurrAlert = 0;
                const alertNoDealList = res.data.filter(r=>r.alarm_process===1);

                if(alertNoDealList.length<1) {
                    //右上角显示
                    store.dispatch({type:"roomAlertCurr",value: { title: '室内暂无报警',color:"#16ef08",pic: "/images/R01.svg" }})
                }else{
                    // 报警文字
                    store.dispatch({type:"roomAlertCurr",value: {
                        title: alertMap(alertNoDealList[0].alert_type).title,
                        color:alertNoDealList[0].alert_type===6?"#fffc00":"#FF002A",
                        pic: alertNoDealList[0].alert_type===6?"/images/R03-6.svg":"/images/R03.svg"}
                    });

                    let audioLength = localStorage.getItem("audioLength");
                    if(audioLength===null || audioLength==="") audioLength = "15";
                    audioLength = Number(audioLength);

                    // 报警声音
                    if(audioLength===-1 || (new Date().getTime()-new Date(alertNoDealList[0].handle_time).getTime())<1000*audioLength){
                        audioCurrAlert = alertNoDealList[0].alert_type;
                    }
                }
                store.dispatch({type:"audioCurrAlert",value: audioCurrAlert})
                store.dispatch({type:"roomAlertList",value: res.data})
                store.dispatch({type:"roomAlertTime",value: new Date().getTime()})
            }
            this.setState({initFlag:true,scanTime:new Date().getTime()})
        }).catch();
        this.scanAlertListHandle = setTimeout(this.scanAlertList,3000);
    }


    showModal = (alert_id,e) => {
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:false,falseLoading:false,});
        this.setState({
            scanTime: new Date().getTime(),
            clientX: Math.round(e.clientX/2-170),
            clientY: Math.round(e.clientY-190),
            modalMap});
    }

    handleCancel = (alert_id) => {
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
        this.setState({scanTime: new Date().getTime(),modalMap});
    }

    okSubmit = (room_id,alert_id,e) =>{
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:true,falseLoading:false,});
        this.setState({modalMap});
        alarmProcess({
            token:localStorage.getItem("curr_token"),
            api_mode:localStorage.getItem("curr_api_mode"),
            alert_id:alert_id}).then(res=>{
            if (res.success){
                this.scanAlertList();
                modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
                this.setState({scanTime: new Date().getTime(),modalMap});
            }
        });
    }


    falseSubmit = (room_id,alert_id,e) => {
        // console.log(room_id,alert_id)
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:false,falseLoading:true,});
        this.setState({scanTime: new Date().getTime(),modalMap});
        alarmProcessMisreport({
            token:localStorage.getItem("curr_token"),
            api_mode:localStorage.getItem("curr_api_mode"),
            alert_id:alert_id}).then(res=>{
            if (res.success){
                this.scanAlertList();
                modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
                this.setState({scanTime: new Date().getTime(),modalMap});
            }
        });
    }


    componentDidMount(){
        this.unSubscribe = store.subscribe(()=>{ this.setState(store.getState()); })
        this.scanAlertList()
    }

    componentWillUnmount(){
        this.setState = () => false;
        this.unSubscribe();
        if(this.scanAlertListHandle) clearTimeout(this.scanAlertListHandle)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.scanTime!==this.state.scanTime
    }

    render() {
        const {initFlag,roomAlertList,modalMap,clientX,clientY} = this.state;

        return (
            <div className={Style.alert_list} style={{
                position:"relative",
                backgroundImage:"url('/images/bgicon.png')",
                backgroundRepeat:"no-repeat",
                backgroundSize:"30%",
                backgroundPosition:"80% 90%"}}>
                {initFlag===true && roomAlertList.length<1 && (<div style={{position:"absolute",left:"36%",top:"16%",width:"25%",textAlign:"center",color:"rgb(150,154,249)",fontSize:".95rem"}} >
                    <img src={"/images/noalert.png"} alt="" style={{width:"100%"}} />
                    <div style={{margin:"1rem 0"}}>无报警记录</div>
                    </div>)}
                {roomAlertList?.slice(0,5).map(res=>{
                    // console.log(res.alert_type,res.device_type,alertMap(res.device_type).src2)
                    return <div className={Style.main} key={res.alert_id} >
                        <div className={Style.row}>
                            <div className={Style.left}><img src={(res.alert_type===18 || res.alert_type===19)?alertMap(res.device_type).src2:alertMap(res.alert_type).src2} alt="" /></div>
                            <div className={Style.right}>
                                <div className={Style.right1}>
                                    <div className={Style.room_name}>{localStorage.getItem("curr_room_name")}</div>
                                    <div className={res.alert_type===6?Style.alert_type_txt6:Style.alert_type_txt}>{res.alert_type_txt}</div>
                                </div>
                                <div className={Style.right2}>
                                 <div className={Style.create_time}>{res.handle_time}</div>
                                    {res.alarm_process===1 && (
                                    <div className={res.alert_type===6?Style.deal6:Style.deal}>
                                        <Modal
                                            key={`modal-${res.alert_id}`}
                                            visible={modalMap.get(res.alert_id)?.visible}
                                            title={''}
                                            onCancel={this.handleCancel.bind(this,res.alert_id)}
                                            width={280}
                                            style={{top:clientY,left:clientX}}
                                            footer={false}
                                        >
                                            <p><b>{res.alert_type_txt}</b><br />
                                                {res.handle_time}
                                            </p>
                                            <div className={Style.dealBtn}>
                                            <Button key="false" type="default"  loading={modalMap.get(res.alert_id)?.falseLoading} onClick={this.falseSubmit.bind(this,res.room_id,res.alert_id)}>
                                                这是误报
                                            </Button>
                                            <Button key="ok" type="default" danger loading={modalMap.get(res.alert_id)?.okLoading} onClick={this.okSubmit.bind(this,res.room_id,res.alert_id)}>
                                                处理报警
                                            </Button>
                                            </div>
                                        </Modal>

                                        <button onClick={this.showModal.bind(this,res.alert_id)}>处理报警</button>

                                        {/*<button onClick={(e)=>{*/}
                                        {/*e.target.innerHTML="处理中... ";*/}
                                        {/*e.target.disabled="disabled"*/}
                                        {/*alarmProcess({*/}
                                        {/*    api_mode:localStorage.getItem("curr_api_mode"),*/}
                                        {/*    token:localStorage.getItem("curr_token"),*/}
                                        {/*    alert_id:res.alert_id}).then(res=>{*/}
                                        {/*    if(this.scanAlertListHandle) clearTimeout(this.scanAlertListHandle)*/}
                                        {/*    this.scanAlertList();*/}
                                        {/*});*/}
                                        {/*}}>处理报警</button>*/}
                                    </div>
                                )}
                                <div className={Style.play}><img src={"/images/li03.png"} alt=""  style={{width:"100%"}}/></div>
                                </div>
                            </div>
                        </div>
                        <div className={Style.bottom}></div>
                    </div>;
                })}
            </div>
        )
    }
}
