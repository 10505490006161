import React from 'react';
import * as echarts from 'echarts';

export default class BreathSimulationMap extends React.Component {
  heart = 0;
  xAxisData = [];
  yAxisData = [];
  totalFlowRate;
  totalFlowRateOption;
  yWidth = 800;
  componentDidMount() {
    // var data = [116,129,135,86,73,73,73,73,73,73,73,85,73,68,85,90,90,90,90,180,300];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.yWidth; i++) {
      this.yAxisData.push(null);
    }
    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }
    this.totalFlowRateOption = {
      animation: false,
      title: {
        text: ``,
        right: '0vw',
        textStyle: {
          color: '#00B2FD',
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      grid: {
        left: 10,
        right: 10,
        top: '30%',
        height: '40%',
      },
      xAxis: {
        boundaryGap: false,
        data: this.xAxisData,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#00B2FD',
          },
        },

      },
      yAxis: {
        min: 0,
        max: 100,
        interval: 1,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: ['#00B2FD'],
          },
        },
        boundaryGap: false,
      },
      series: {
        symbol: 'none' /* 去掉小圆点 */,
        name: '当前值',
        type: 'line',
        data: this.yAxisData ,
        smooth:true,
        lineStyle: {
          // color: '#00B2FD',
        },
      },
    };

    const heartMap = document.getElementById(`breathMap${this.props.id}`);
    if (this.totalFlowRate === undefined) {
      this.totalFlowRate = echarts.init(heartMap);
      this.totalFlowRate.setOption(this.totalFlowRateOption,true)
    }

    setTimeout(this.drawLine,1000)
  }


  shouldComponentUpdate(nextProps, nextState) {
    // return nextProps.breathHeart.time!==this.props.breathHeart.time
    return true;
  }


  drawLineHandle = undefined;
  drawFlag = true;
  y=0;
  yIndex=0;
  rand = 0;
  drawLine = ()=>{
    if(this.yIndex===0) this.rand = Number((Math.random()*10).toFixed(2));

    if(this.drawFlag){
      let hotNum = this.props.hotList.filter(e=>e>new Date().getTime()-2000).length;
      if(hotNum>0){
        this.y = Number((20+this.rand+(20+this.rand) * Math.sin(this.yIndex / 360 * 2 * Math.PI)).toFixed(2));
      }else {
        this.y = 0;
      }
      // console.log(this.y)
      this.yAxisData.push(this.y);
      if (this.yAxisData.length > this.yWidth) this.yAxisData.shift();
      this.totalFlowRate.setOption(this.totalFlowRateOption,true);
      this.yIndex +=4;
      if (this.yIndex>360) this.yIndex=0;
    }
    this.drawLineHandle = setTimeout(this.drawLine,25);
  }


  componentWillUnmount() {
    if(this.drawLineHandle) clearTimeout(this.drawLineHandle);
  }

  render() {
    return (
        <>
          <div
              id={`breathMap${this.props.id}`}
              style={{ height: '15vh',width:"15vw", backgroundColor: 'rgba(255, 255, 255, 0)' }}
          ></div>
        </>
    );
  }
}
