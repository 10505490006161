import React, { Component } from 'react'
import {Modal, Button, Popconfirm} from 'antd';

import {getAlertList, alarmProcess, alarmProcessMisreport,alarmProcessAll} from 'service/service'
import store from 'store/index'
import {alertMap} from 'utils/common'
import Style from './style.module.css'

function bubbleSort (arr) {
    var max = arr.length - 1;
    for (var j = 0; j < max; j++) {
        // 声明一个变量，作为标志位
        var done = true;
        for (var i = 0; i < max - j; i++) {
            if (arr[i + 1].room_status===44) {
                if(arr[i].room_status===1 || arr[i].alert_time<arr[i+1].alert_time){
                    var temp = arr[i];
                    arr[i] = arr[i + 1];
                    arr[i + 1] = temp;
                    done = false;
                }
            }
        }
        if (done) {
            break;
        }
    }
    return arr;
}

export default class AlertList extends Component {
    constructor(props){
        super(props)
        this.state = {
            clientX:0,
            clientY:0,
            modalMap: new Map(),
            ...store.getState()
        };
    }
    unSubscribe = ()=>{}
    scanAlertListHandle = undefined;

    scanAlertList = ()=>{
        let {roomList} = this.state;
        clearTimeout(this.scanAlertListHandle)
        getAlertList().then(res=>{
            if(res.data){
                const alertNoDealList = res.data.filter(r=>r.alarm_process===1);
                let audioCurrAlert = 0;

                // 语音报警
                if(alertNoDealList.length>0) {
                    if((new Date().getTime()-new Date(alertNoDealList[0].handle_time).getTime())<1000*15){
                        audioCurrAlert = alertNoDealList[0].alert_type;
                    }
                }

                store.dispatch({type:"audioCurrAlert",value: audioCurrAlert})
                store.dispatch({type:"alertList",value:res.data})


                const alertRoom = {};
                alertNoDealList.forEach(r=>{
                    // console.log("alertNoDealList",r.room_id,r.alert_type)
                    if(alertRoom[r.room_id] === undefined){
                        alertRoom[r.room_id] = {alert_type:r.alert_type,handle_time: r.handle_time,};
                    }
                });

                roomList = roomList.map(r => {
                    if(alertRoom.hasOwnProperty(r.room_id)){
                        r.room_status = 44;
                        r.alert_type = alertRoom[r.room_id].alert_type;
                        r.alert_time = alertRoom[r.room_id].handle_time;
                    }else{
                        r.room_status = 1;
                        r.alert_type = 0;
                    }
                    return r;
                })


                if(roomList && roomList.length>0){

                    roomList = bubbleSort(roomList) //冒泡排序
                    store.dispatch({type:"roomList",value:roomList})
                }
            }
        });
        this.scanAlertListHandle = setTimeout(this.scanAlertList,3000);
    }

    showModal = (alert_id,e) => {
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:false,falseLoading:false,});
        this.setState({
            clientX: Math.round(e.clientX/2-170),
            clientY: Math.round((e.clientY-190)<20?20:(e.clientY-190)),
            modalMap});
    }

    handleCancel = (alert_id) => {
        const {modalMap} = this.state;
        modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
        this.setState({modalMap});
    }

    okSubmit = (room_id,alert_id,e) =>{
        const {roomList,modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:true,falseLoading:false,});
        this.setState({modalMap});
        const currRoom = roomList.filter(r=>r.room_id===room_id);
        alarmProcess({
            token:currRoom[0].token,
            api_mode:currRoom[0].api_mode,
            alert_id:alert_id}).then(res=>{
                if (res.success){
                    this.scanAlertList();
                    modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
                    this.setState({modalMap});
                }

        });
    }


    falseSubmit = (room_id,alert_id,e) => {
        console.log(room_id,alert_id)
        const {roomList,modalMap} = this.state;
        modalMap.set(alert_id,{visible:true,okLoading:false,falseLoading:true,});
        this.setState({modalMap});
        const currRoom = roomList.filter(r=>r.room_id===room_id);
        console.log(currRoom)
        alarmProcessMisreport({
            token:currRoom[0].token,
            api_mode:currRoom[0].api_mode,
            alert_id:alert_id}).then(res=>{
                if (res.success){
                    this.scanAlertList();
                    modalMap.set(alert_id,{visible:false,okLoading:false,falseLoading:false,});
                    this.setState({modalMap});
                }
        });
    }

    alarmProcessAllSubmit = ()=>{
        alarmProcessAll().then(r => {
            console.log(r);
            this.scanAlertList();
        })
    }

    componentDidMount(){
        this.unSubscribe = store.subscribe(()=>{ this.setState(store.getState()); })

        setTimeout(()=>{
            this.scanAlertList()
        },200)
    }

    componentWillUnmount(){
        this.setState = () => false;
        this.unSubscribe();
        if(this.scanAlertListHandle) clearTimeout(this.scanAlertListHandle)
    }
    render() {
        let {alertList,modalMap,clientX,clientY} = this.state;
        alertList = alertList?.slice(0,7);
        return (
            <div className={Style.alert_list} style={{
                display:this.props.alertVisible?"":"none",
                backgroundImage:"url('/images/bgicon.png')",
                backgroundRepeat:"no-repeat",
                backgroundSize:"30%",
                backgroundPosition:"50% 90%",
                overflow:"auto"
                }}>
                {alertList?.length<1 && (<div style={{position:"absolute",left:"36%",top:"16%",width:"25%",textAlign:"center",color:"rgb(150,154,249)",fontSize:".95rem"}} >
                    <img src={"/images/noalert.png"} alt=""  style={{width:"100%"}}/>
                    <div style={{margin:"1rem 0"}}>无报警记录</div>
                    </div>)}
                {alertList?.map((res,index)=>{
                    return <div className={Style.main} key={res.alert_id} >

                        <div className={Style.row}>
                            <div className={Style.left}>
                                <img src={(res.alert_type===18 || res.alert_type===19)?alertMap(res.device_type)?.src2:alertMap(res.alert_type).src2} alt="" />
                            </div>
                            <div className={Style.right}>
                                <div className={Style.right1}>
                                    <div className={Style.room_name}>{res.room_name}</div>
                                    <div className={res.alert_type===6?Style.alert_type_txt6:Style.alert_type_txt}>{res.alert_type_txt}</div>
                                </div>
                                <div className={Style.right2}>
                                    <div className={Style.create_time}>{res.handle_time}</div>
                                    {res.alarm_process===1 && (
                                    <div className={res.alert_type===6?Style.deal6:Style.deal}>
                                        <Modal
                                            key={`modal-${res.alert_id}`}
                                            visible={modalMap.get(res.alert_id)?.visible}
                                            // bodyStyle={{backgroundColor:'#e3f0fc'}}
                                            title={""}
                                            onCancel={this.handleCancel.bind(this,res.alert_id)}
                                            width={260}
                                            style={{top:clientY,left:clientX}}
                                            footer={null}
                                        >
                                            <p><b>{res.alert_type_txt}</b><br />
                                                {res.handle_time}
                                            </p>
                                            <div className={Style.dealBtn}>
                                                <Button key="false" type="default"  loading={modalMap.get(res.alert_id)?.falseLoading} onClick={this.falseSubmit.bind(this,res.room_id,res.alert_id)}>
                                                    这是误报
                                                </Button>
                                                <Button key="ok" type="primary" danger  loading={modalMap.get(res.alert_id)?.okLoading} onClick={this.okSubmit.bind(this,res.room_id,res.alert_id)}>
                                                    处理报警
                                                </Button>
                                            </div>

                                        </Modal>

                                        <button onClick={this.showModal.bind(this,res.alert_id)}>处理报警</button>

                                       </div>
                                    )}

                                    <div className={Style.play}><img src={"/images/li03.png"} alt="" style={{width:"100%"}} /></div>
                                </div>
                                </div>
                            </div>
                        <div className={index===alertList.length-1?Style.bottom6:Style.bottom}></div>
                    </div>;
                })}

                {alertList?.length>1 && (
                    <div className={Style.bottomDiv}>
                        <Popconfirm
                            title="请确认处理所有报警记录吗？"
                            onConfirm={()=>{
                                this.alarmProcessAllSubmit()
                            }}
                            okText="确定"
                            okButtonProps={{ danger: true }}
                            cancelText="取消"
                        >
                            <button>批量处理报警</button>
                        </Popconfirm>
                    </div>
                )}

            </div>
        )
    }
}
