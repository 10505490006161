import React from 'react';
import * as echarts from 'echarts';

export default class AyiBreathChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    yAxisData2 = [];
    eChart;
    option;
    yWidth = 1800;
    breath = 0;
    timeHandle = undefined
    initChart = ()=>{
        for (let i = 1; i <= this.yWidth; i++) {
            this.yAxisData.push(null);
            this.yAxisData2.push(null);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
            this.yAxisData2.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: `呼吸`,
                left: '20',
                textStyle: {
                    fontSize:"32px"
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '10%',
                height: '80%',
                containLabel: true
            },
            xAxis: {
                type:"category",
                boundaryGap: false,
                data: this.xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: '#f2f2f2',
                    },
                },
            },
            yAxis: {
                // min: -1.4,
                // max: 1.4,
                // interval: 0.7,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: '#f2f2f2',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        // color: '#333333',
                        fontSize:"26px"
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        // color: ['#f2f2f2'],
                    },
                },
                // boundaryGapboundaryGap: true,
            },
            series:
                // [
                    {
                // symbol: 'none' /* 去掉小圆点 */,
                // name: '当前值',
                type: 'line',
                data: this.yAxisData,
                smooth:false /* ,//显示为平滑的曲线 */,
                lineStyle: {
                    color: '#3DE0C0',
                },
                },
            // {
            //     // symbol: 'none' /* 去掉小圆点 */,
            //     // name: '当前值',
            //     type: 'line',
            //     data: this.yAxisData2,
            //     // smooth:false /* ,//显示为平滑的曲线 */,
            //     // lineStyle: {
            //     //     color: '#3DE0C0',
            //     // },
            // }
            // ],
        };

        const heartMap = document.getElementById(`breathChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap,'dark');
        }
        this.play()
    }

    componentDidMount() {
        this.initChart();
        this.play()
    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle);
        this.timeHandle = undefined
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }

    playArr = [];
    frame = 100
    play=()=>{
        let wave = 0;
        let len = this.playArr.length;
        if (len>0) {
            wave = this.playArr[0].b_wave;
            this.breath = this.playArr[0].breath;
            this.playArr.shift();
            this.yAxisData.push(wave);
            this.yAxisData2.push(this.getPingjun(this.yAxisData));
            this.yAxisData.shift();
            this.yAxisData2.shift();
            this.option.title.text = "";
            this.eChart.setOption(this.option);
        }
        let timeout = 20;
        this.timeHandle = setTimeout(()=>{
            this.play()
        },timeout)
    }
    getPingjun=(arr)=>{
        let a = 0;
        let b;
        let num=200;
        for (let i=arr.length-1;i>arr.length-num-1;i--){
            a += arr[i];
        }
        b = a/num;
        // console.log(arr.length,a,b)
        return b;

    }

    myBH = undefined
    componentDidUpdate() {
        let {bh_info,myBH} = this.props;
        this.myBH = myBH
        bh_info?.forEach(e=>{
            this.playArr.push(e);
        })
        this.eChart.setOption(this.option);
    }

    render() {
        return (
            <div style={{display:"flex",height:"100%"}}>
                <div style={{display:"innerFlex",flex:"1",height:"10%"}} >
                    <div style={{display:"innerFlex",flexDirection: "column",color:"white",fontSize:"3vh",paddingLeft:"1vw",justifyContent:"space-around"}}>
                        <div style={{width:"200px",height:"20%"}}>呼吸值</div>
                        {/*<div style={{width:"200px",height:"60%",textAlign:"right",paddingRight:"0.5vw"}}><span style={{fontSize:"15vh",color:"#3DE0C0"}}>{this.myBH?.b20}</span></div>*/}
                        <div style={{width:"200px"}}>设备计算：{this.breath}</div>
                        <div style={{width:"200px"}}>波峰计算：{this.myBH?.b10}</div>
                        <div style={{width:"250px"}}>波峰滤波：{this.myBH?.b11}</div>
                        <div style={{width:"200px"}}>过零计算：{this.myBH?.b20}</div>
                        <div style={{width:"200px"}}>过零滤波：{this.myBH?.b21}</div>
                        <div style={{width:"200px"}}>综合计算：{this.myBH?.b50}</div>
                    </div>
                </div>

                <div
                    id={`breathChart${this.props.id}`}
                    style={{flex:"10",padding:"0 20px", height: '90%',width:'100%'}}
                ></div>
            </div>
        );
    }
}
