import React from 'react';
import * as echarts from 'echarts';
// import ReactAudioPlayer from "react-audio-player";

export default class BreathChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    yWidth = 500;
    dataList = [];
    timeHandle = undefined;
    breath = 20;
    data = [];
    dataIndex=0;
    hasPeople = 0;
    hasSport = 0;
    hasBreath = 0;

    getData(){
        let A = [0,3,6,6,9,12,14,16,18,20,22,24,26,28,29,30,31,32,33,34,35,36,37,33,29,24,20,16,12,8,4,0,-3,-6,-9,-12,-14,-16,-18,-19,-20,-21,-22,-23,-24,-25,-26,-27,-28,-29,-29.5,-30,-29,-26,-22,-18,-14,-10,-6,-3];
        this.data = A
    }

    getData0(){
        let data = [];
        for (let i=0;i<60;i++){
            data.push(0)
        }
        this.data = data;
    }
    getDataRandom=()=>{
        let data = [];
        for (let i=0;i<60;i++){
            data.push(20-Math.random()*40)
        }
        this.data = data;
    }

    scanData = ()=>{
        clearTimeout(this.timeHandle)
        let flag = true
        // if (this.breath < 12) this.breath = 20
        // if (this.breath > 0 && this.breath < 5) this.breath = 5

        if (this.hasPeople===0){
            this.getData0();
            flag = false;
        }else{
            if (this.hasBreath===1){
                if (this.hasSport==1){//体动
                    this.getDataRandom();
                }else{
                    this.getData()
                }
                flag = false
            }else{
                this.getData0();
                flag = false;
            }
        }

        // //有人，可能因为屏住呼吸
        // if (flag && this.hasPeople>0){
        //     if (this.hasBreath==1){
        //         if (this.p_num>1200){//体动
        //             this.getDataRandom();
        //         }else{
        //             this.getData()
        //         }
        //         flag = false
        //     }else{
        //         this.getData0();
        //         flag = false;
        //     }
        // }
        // //没人，一定没呼吸
        // if(flag && this.hasPeople==0){
        //     this.getData0();
        //     flag = false;
        // }

        if (flag){
            this.getData()
        }


            // // 有人
        // if (flag && this.hasPeople>0){
        //     if (this.p_num>1200){//体动
        //         this.getDataRandom();
        //         flag = false
        //     }else{
        //         this.getData()
        //     }
        // }
        //
        // //没人
        // if(flag && this.hasPeople == 0){
        //     this.getData0();
        //     flag = false;
        // }
        //
        // if (flag){
        //     this.getData()
        // }

        let frame = 6;

        if (this.dataIndex>this.data.length-1) this.dataIndex=0;
        for (let i=0;i<frame;i++){
            let ware = this.data[this.dataIndex++];
            this.yAxisData.push(ware);
            this.yAxisData.shift();
        }
        this.eChart.setOption(this.option);

        // console.log(this.data.length)

        let timeout = 100;
        if (this.breath>10) timeout=timeout*60/this.breath;
        if (this.breath<=10) timeout = 300

        // console.log("timeout",timeout)
        // console.log(timeout,this.breath)
        // console.log(new Date().getDate())

        this.timeHandle = setTimeout(this.scanData,timeout);
    }


    componentDidMount() {
        for (let i = 1; i <= this.yWidth; i++) {
            this.yAxisData.push(null);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: ``,
                right: '0vw',
                textStyle: {
                    color: '#3DE0C0',
                    fontSize: 20,
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '10%',
                height: '80%',
            },
            xAxis: {
                boundaryGap: false,
                data: this.xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#f2f2f2',
                    },
                },
            },
            yAxis: {
                min: -100,
                max: 100,
                interval: 1,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: ['#3DE0C0'],
                    },
                },
                boundaryGap: false,
            },
            series: {
                symbol: 'none' /* 去掉小圆点 */,
                name: '当前值',
                type: 'line',
                data: this.yAxisData,
                smooth:false /* ,//显示为平滑的曲线 */,
                lineStyle: {
                    color: '#3DE0C0',
                },
            },
        };

        const breathMap = document.getElementById(`breathChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(breathMap);
        }
        this.scanData();

    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle);
        this.timeHandle = undefined
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathBreath.time!==this.props.breathBreath.time
        return true
    }


    componentDidUpdate() {
        let {breath,hasBreath,hasPeople,hasSport,close_to} = this.props;
        this.breath = breath;
        this.hasSport = hasSport
        this.close_to = close_to
        this.hasPeople = hasPeople
        this.hasBreath = hasBreath
    }

    render() {
        return (
            <>
                <div
                    id={`breathChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%', backgroundColor: '#dfdfdf'  }}
                ></div>
            </>
        );
    }
}
