import {Route, Redirect, Switch, HashRouter} from 'react-router-dom';
import RoutesConfig from 'config/routes.config';
import AuthRoute from 'components/AuthRoute';
import {getUser} from 'service/service';

import {createBrowserHistory} from 'history';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import './App.css';

import React, { Component } from 'react';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      history: createBrowserHistory()
    }
  }
  componentDidMount(){
  }

  render() {
    const user = getUser();
    return (
      <>
        {/* <div style={{position:"absolute",top:0,heigth:0,width:"100vw",height:"100vh",opacity:0.5,zIndex:1,backgroundColor:"rgb(9,7,68)"}}></div> */}
        <HashRouter>
            <Switch>
              <Route path='/' render={()=>(<Redirect to='/monitor/index' />)} history exact></Route>
              {RoutesConfig.map(
                (route) => {
                  if(route.needLogin){
                    return <AuthRoute key={route.path} {...route} {...user} history/>
                  }
                    return <Route key={route.path} {...route} history />
                }
              )}
            </Switch>
        </HashRouter>
      </>
    );
  }
}

export default App;
