const defaultState = {
    roomList:[],//房间列表，收到报警信息，报警处理等操作需要同步更新房间列表
    alertList:[],//房间列表页面的报警列表
    audioConfirm: true,//默认打开弹窗
    audioSwitch: false,//声音默认关闭
    audioCurrAlert: 0 ,// 当前报警语音，默认0 {'1': '卫生间摔倒','2': '卫生间滞留','3': '室内摔倒','4': '异常离床','5': '异常下床','11': '烟雾监测','12': '燃气监测','13': '浸水监测','14': '门磁监测','15': '拉绳报警','16': 'SOS报警'};
    roomAlertList:[],//房间报警列表
    roomAlertTime:0,//房间报警时间
    roomAlertCurr:{ title: '室内暂无报警',color:"#16ef08",pic: "/images/R01.svg" }, //房间右上角报警
}
const reducer = (state = defaultState,action) => {
    let newState = {...state}
    if(action.type==="roomList") newState.roomList = action.value
    if(action.type==="audioConfirm")  newState.audioConfirm = action.value
    if(action.type==="audioSwitch")  newState.audioSwitch = action.value
    if(action.type==="audioCurrAlert")  newState.audioCurrAlert = action.value
    if(action.type==="alertList")  newState.alertList = action.value
    if(action.type==="roomAlertList")  newState.roomAlertList = action.value
    if(action.type==="roomAlertTime")  newState.roomAlertTime = action.value
    if(action.type==="roomAlertCurr")  newState.roomAlertCurr = action.value

    return newState
}

export default reducer
