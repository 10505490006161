import React, { Component } from 'react'
import Style from './BreathHeart.module.css'
import HeartSimulationMap1018 from "../HeartMap/HeartSimulationMap1018";
import BreathSimulationMap1018 from "../BreathMap/BreathSimulationMap1018";
// import {nanoid} from 'nanoid';


export default class BreathHeartSimulation1018 extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    componentDidMount(){
    }
    render() {
        let {hotList} = this.props;
        return (
            <>
            <div className={Style.main}>
                <div className={Style.bedList} style={{width:"90vw"}}>
                    <div className={Style.col} style={{width:"70vw"}}>
                        <div className={Style.bh} style={{margin:"0 0 0 15vw"}}>
                        <div className={Style.bh_col1}></div>
                        <div className={Style.bh_col2}></div>
                        <div className={Style.bh_col3}  >
                            <div className={Style.bh_col3_c1}>
                                <img src="/images/R07-12.png" alt="" />
                            </div>
                            <div className={Style.bh_col3_c2}>
                                <HeartSimulationMap1018 id={111} hotList={hotList} />
                            </div>
                            <div className={Style.bh_col3_c3}>
                                <img src="/images/R08-12.png" alt="" />
                            </div>
                            <div className={Style.bh_col3_c4}>
                                <BreathSimulationMap1018 id={2222} hotList={hotList} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </>
        )
    }
}
