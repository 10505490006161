import React from 'react';
import * as echarts from 'echarts';

export default class HeartSimulationMap1018 extends React.Component {
  heart = 0;
  xAxisData = [];
  yAxisData = [];
  totalFlowRate;
  totalFlowRateOption;
  yWidth = 20;
  componentDidMount() {
    // var data = [116,129,135,86,73,73,73,73,73,73,73,85,73,68,85,90,90,90,90,180,300];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.yWidth; i++) {
      this.yAxisData.push(null);
    }
    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }
    this.totalFlowRateOption = {
      animation: false,
      title: {
        text: ``,
        right: '0vw',
        textStyle: {
          color: '#00B2FD',
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      grid: {
        left: 10,
        right: 10,
        top: '10%',
        height: '50%',
      },
      xAxis: {
        boundaryGap: false,
        data: this.xAxisData,
        axisLine: {
          show: false,//-----------
          lineStyle: {
            color: '#078CD6',
          },
        },
      },
      yAxis: {
        max: 100,
        min: 50,
        interval: 1,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: ['#078CD6'],
          },
        },
        boundaryGap: false,
      },
      series: {
        symbol: 'none' /* 去掉小圆点 */,
        name: '当前值',
        type: 'line',
        data: this.yAxisData,
        smooth:true, //显示为平滑的曲线 */,
        lineStyle: {
          color: 'red',
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'red'
            },
            {
              offset: 1,
              color: '#ee7474'
            }
          ])
        },
      },
    };

    const heartMap = document.getElementById(`heartMap${this.props.id}`);
    if (this.totalFlowRate === undefined) {
      this.totalFlowRate = echarts.init(heartMap);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.breathHeartSimulation.time!==this.props.breathHeartSimulation.time
  }

  componentDidUpdate() {
    let {heart} = this.props.breathHeartSimulation;
    this.yAxisData.push(heart);
    this.yAxisData.shift();
    this.totalFlowRate.setOption(this.totalFlowRateOption);
  }


  render() {
    return (
        <>
          <div
              id={`heartMap${this.props.id}`}
              style={{ height: '15vh',width:"15vw", backgroundColor: 'rgba(255, 255, 255, 0)' }}
          ></div>
        </>
    );
  }
}
