import React, { Component } from 'react'
import {getRoomList} from 'service/service'
import store from 'store/index'
import Style from './style.module.css'

export default class AlertList extends Component {
    constructor(props){
        super(props)
        this.state = store.getState();
    }
    unSubscribe = ()=>{}
    handleStoreChange = ()=>{
        this.setState(store.getState());
    }
    scanRoomList = ()=>{
        getRoomList().then(res=>{
            store.dispatch({type:"roomList",value:res.data})
        });
    }
    componentDidMount(){
        this.unSubscribe =  store.subscribe(this.handleStoreChange)
        this.scanRoomList()
    }
    componentWillUnmount(){
        store.dispatch({type:"roomList",value:[]})
        this.setState = () => false;
        this.unSubscribe();
    }
    render() {
        const {roomList} = this.state;
        // console.log(JSON.stringify(roomList))
        return (
            <div className={Style.roomlist}>
                {roomList?.map(res=>{
                    return <div className={Style.room} key={res.room_id}>
                        <div className={Style.normal}  onClick={()=>{
                            localStorage.setItem("curr_room_id",res.room_id)
                            localStorage.setItem("curr_token",res.token)
                            localStorage.setItem("curr_api_mode",res.api_mode)
                            localStorage.setItem("curr_room_name",res.room_name)
                            this.props.history.push('/monitor/room')
                        }}>
                        {res.room_status===44?
                        (<><img alt="" src="/images/li02.png"  style={{width:"100%"}}/> <img className={Style.shan} alt="" src="/images/lim03.gif" style={{width:"100%"}}/></>)
                        :<><img alt="" src="/images/li04.png"  style={{width:"100%"}}/> </>
                        }
                        </div>
                        <div className={Style.room_name}>{res.room_name}</div>
                    </div>;
                })}
            </div>
        )
    }
}
