import React from 'react';
import * as echarts from 'echarts';

export default class VarianceChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    yWidth = 2000;
    heart = 0;
    initChart = ()=>{
        for (let i = 1; i <= this.yWidth; i++) {
            this.xAxisData.push(0);
            this.yAxisData.push(0);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: `方差`,
                left: '20',
                textStyle: {
                    fontSize:"32px"
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '10%',
                height: '80%',
                containLabel: true
            },
            xAxis: {
                boundaryGap: true,
                data: this.xAxisData,
                axisLabel: {
                    formatter:function (e){
                        if(e==0){
                            return ""
                        }
                        return e.substr(11,6)
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#000000',
                    },
                },
            },
            yAxis: {
                // min: 0,
                // max: 10000,
                // interval: 500,
                // min: -0.05,
                // max: 0.05,
                // interval: 0.005,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: '#000000',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#000000',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#f2f2f2'],
                    },
                },
                // boundaryGap: true,
            },
            series: {
                symbol: 'none', /* 去掉小圆点 */
                name: '当前值',
                type: 'line',
                data: this.yAxisData,
                itemStyle : {
                    normal: {
                        label : {
                            show: false,
                            formatter:function (e){
                                return Number(e.value).toFixed(0)
                            }
                        }
                    }},
                smooth:false /* ,//显示为平滑的曲线 */,
                lineStyle: {
                    color: 'red',
                },
            },
        };

        const heartMap = document.getElementById(`varianceChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap);
        }
    }


    componentDidMount() {
        this.initChart();
    }


    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }


    idx = 0
    componentDidUpdate() {
        let {vData} = this.props;
        this.idx++

        vData = JSON.parse(vData)
        this.option.title.text = "方差";

        this.xAxisData.push(vData.time);this.xAxisData.shift();
        this.yAxisData.push(vData.value);this.yAxisData.shift();

        if (this.idx%100==0){
            this.eChart.setOption(this.option);
        }
    }

    render() {
        return (
            <>
                <div
                    id={`varianceChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%'}}
                ></div>
            </>
        );
    }
}
