import React from 'react';
import * as echarts from 'echarts';

export default class AyiVarianceChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    yWidth = 30;
    heart = 0;
    initChart = ()=>{
        for (let i = 1; i <= this.yWidth; i++) {
            this.yAxisData.push(null);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: `方差`,
                left: '20',
                textStyle: {
                    fontSize:"32px"
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '10%',
                height: '80%',
                containLabel: true
            },
            xAxis: {
                boundaryGap: true,
                data: this.xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        // color: '#f2f2f2',
                    },
                },
            },
            yAxis: {
                min: 0,
                max: 25000,
                interval: 500,
                // min: -0.05,
                // max: 0.05,
                // interval: 0.005,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        // color: '#f2f2f2',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        // color: '#999999',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        // color: ['#f2f2f2'],
                    },
                },
                // boundaryGap: true,
            },
            series: {
                // symbol: 'none' /* 去掉小圆点 */,
                name: '当前值',
                type: 'line',
                data: this.yAxisData,
                itemStyle : {
                    normal: {
                        label : {
                            show: true,
                            formatter:function (e){
                                // console.log(e.value)
                                return e.value.toFixed(0)
                            }
                        }
                    }},
                smooth:false /* ,//显示为平滑的曲线 */,
                lineStyle: {
                    color: 'red',
                },
            },
        };

        const heartMap = document.getElementById(`varianceChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap,"dark");
        }
        this.play()
    }


    componentDidMount() {
        this.initChart();
    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle);
        this.timeHandle = undefined
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }

    playArr = [];
    frame = 2
    play=()=>{
        let wave = 0;
        let len = this.playArr.length;
        // console.log(this.playArr)

        for (let i=0;i<len;i++){
            // wave = this.playArr[0].variance;
            // this.heart = this.playArr[0].heart;
            let variance = this.playArr[i].variance
            this.yAxisData.push(variance);
            this.yAxisData.shift();
            break;
        }
        this.playArr.length = 0;
        this.option.title.text = "方差";
        this.eChart.setOption(this.option);



        let timeout = 1000;
        // console.log("len-timeout",len,timeout)
        setTimeout(()=>{
            this.play()
        },timeout)
    }


    componentDidUpdate() {
        let {bh_info} = this.props;
        bh_info?.forEach(e=>{
            this.playArr.push(e)
        })

    }

    render() {
        return (
            <>
                <div
                    id={`varianceChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%'}}
                ></div>
            </>
        );
    }
}
