import React, { Component } from 'react'
import ShowTime from './ShowTime/ShowTime'
import AlertList from './AlertList/AlertList';
import RoomList from './RoomList/RoomList';
import WarnList from './WarnList/WarnList';
import AudioSwitch from '../Room/AudioSwitch/AudioSwitch'
import {outLogin} from 'service/service'
import './Index.css'
import AudioConfirm from 'components/Monitor/Room/AudioConfirm/AudioConfirm'


export default class Index extends Component {
    state = {
        account_name : localStorage.getItem("account_name"),
        user_name : localStorage.getItem("user_name"),
        roomList : [],
        alertVisible: false
    }
    componentDidMount(){

    }
    componentWillUnmount(){
        this.setState = () => false;
    }

    render() {
        const {account_name,alertVisible} = this.state;
        return (
            <>
            <AudioConfirm />
            <div className="idx" style={{
                backgroundColor:"rgb(17,15,107)",
                background: "url(/images/bg.jpg) no-repeat",backgroundSize: "100% 100%"
                }}>
                <div className="head">
                <div className={"topn"}>
                    {/* <div>苗米无感应用监控中心</div> */}
                    {localStorage.getItem("logo_url") ===""?<img src={"/images/topn.png?v=20220124"} alt="" border="0"  style={{width:"100%"}}/>:<img src={localStorage.getItem("logo_url")} alt="" border="0"  style={{width:"100%"}}/>}
                    </div>
                    <div className={"account"}>
                        <img src={"/images/topP.png"}  alt={""} style={{width:"1rem"}} /> {account_name}</div>
                    <div className={"qback"}>
                    <div><img title={"退出登录"} src={"/images/Q.png"} alt="" border="0" onClick={()=>{
                        outLogin()
                        this.props.history.push('/user/login')
                    }}/> </div>
                    </div>
                    {/* <div className={"user"}>{user_name} </div> */}
                    <div className={"date"}><ShowTime></ShowTime></div>
                    <div className={"alert_list_button"}><img src={"/images/toplist.png"} alt="" border="0"  style={{width:"100%"}}  onClick={
                        ()=>{
                            this.setState({alertVisible:!alertVisible});
                        }
                    }/></div>
                    <div className={"voice"}>
                        <AudioSwitch />
                    </div>
                </div>
                <div className="content">
                    <WarnList></WarnList>
                    <RoomList history={this.props.history}></RoomList>
                </div>
                <AlertList alertVisible={alertVisible}></AlertList>
            </div>
            </>
        )
    }
}
