import React, { Component } from 'react'
import {getDeviceList} from 'service/service'
import store from 'store/index'
import Style from './style.module.css'
import { Tooltip } from 'antd';



function getAlertType(device_type){
    let showMap = new Map();
    showMap.set(7,5);
    showMap.set(9,9);
    showMap.set(11,11);
    showMap.set(12,12);
    showMap.set(13,13);
    showMap.set(14,14);
    showMap.set(15,15);
    showMap.set(16,16);
    return showMap.get(device_type);
}

export default class AlertStatus extends Component {
            // const DEVICE_TYPE_PEOPLE = 7;
        // const DEVICE_TYPE_BREATH = 9;
        // const OTHER_DEVICE_TYPE_SMOKE = 11;
        // const OTHER_DEVICE_TYPE_GAS = 12;
        // const OTHER_DEVICE_TYPE_WATER = 13;
        // const OTHER_DEVICE_TYPE_DOOR = 14;
        // const OTHER_DEVICE_TYPE_ROPE = 15;
        // const OTHER_DEVICE_TYPE_SOS = 16;
        // const OTHER_DEVICE_TYPE_BREATH_HEART = 17;
    // 18 低电压报警 19 离线报警
    // src1 默认图片 src2 报警图标 src3 低电量离线图标
    constructor(props){
        super(props)
        this.state = {
            dataList: [
                {type:3, show:0, device_type:7,    title:"摔倒报警",   src:"/images/icon/i01.png",src1:"/images/icon/i01.png",src2:"/images/icon/i01-1.png",src3:"/images/icon/i01-4.png"},
                {type:5,show:0, device_type:7,    title:"来回徘徊",   src:"/images/icon/i02.png",src1:"/images/icon/i02.png",src2:"/images/icon/i02-1.png",src3:"/images/icon/i02-4.png"},
                {type:9,show:0, device_type:9,    title:"呼吸暂停",   src:"/images/icon/i03.png",src1:"/images/icon/i03.png",src2:"/images/icon/i03-1.png",src3:"/images/icon/i03-4.png"},
                {type:-3,show:0, device_type:9,    title:"心率报警",   src:"/images/icon/i04.png",src1:"/images/icon/i04.png",src2:"/images/icon/i04-1.png",src3:"/images/icon/i04-4.png"},
                {type:4, show:0, device_type:7,    title:"异常离床报警",src:"/images/icon/i05.png",src1:"/images/icon/i05.png",src2:"/images/icon/i05-1.png",src3:"/images/icon/i05-4.png"},
                {type:2, show:0, device_type:7,    title:"滞留报警",   src:"/images/icon/i06.png",src1:"/images/icon/i06.png",src2:"/images/icon/i06-1.png",src3:"/images/icon/i06-4.png"},
                {type:15,show:0, device_type:15,    title:"拉绳报警",   src:"/images/icon/i07.png",src1:"/images/icon/i07.png",src2:"/images/icon/i07-1.png",src3:"/images/icon/i07-4.png"},
                {type:16,show:0, device_type:16,    title:"SOS报警",    src:"/images/icon/i08.png",src1:"/images/icon/i08.png",src2:"/images/icon/i08-1.png",src3:"/images/icon/i08-4.png"},
                {type:14,show:0, device_type:14,    title:"门磁报警",   src:"/images/icon/i09.png",src1:"/images/icon/i09.png",src2:"/images/icon/i09-1.png",src3:"/images/icon/i09-4.png"},
                {type:11,show:0, device_type:11,    title:"烟雾报警",   src:"/images/icon/i10.png",src1:"/images/icon/i10.png",src2:"/images/icon/i10-1.png",src3:"/images/icon/i10-4.png"},
                {type:12,show:0, device_type:12,    title:"燃气报警",   src:"/images/icon/i11.png",src1:"/images/icon/i11.png",src2:"/images/icon/i11-1.png",src3:"/images/icon/i11-4.png"},
                {type:13,show:0, device_type:13,    title:"水浸报警",   src:"/images/icon/i12.png",src1:"/images/icon/i12.png",src2:"/images/icon/i12-1.png",src3:"/images/icon/i12-4.png"},
            ],
            ...store.getState()
        }
    }

    timeHandle;
    scanDeviceList = ()=>{
        getDeviceList({
            room_id:localStorage.getItem("curr_room_id"),
            api_mode:localStorage.getItem("curr_api_mode"),
            token:localStorage.getItem("curr_token")
        }).then(res=>{
            if (res.success === false){
                res.data = [{device_type:7},{device_type:9}];//老系统，点亮轨迹和呼吸
                return;
            }
            let {dataList} = this.state;
            dataList = dataList.map(r => {
                r.src=r.src1;
                for(let i=0;i<res.data.length;i++){
                    if(r.device_type === res.data[i].device_type){
                        r.show=1;
                        r.last_online_time = res.data[i].last_online_time;
                        r.status_text =  res.data[i].status_text;
                        break;
                    }
                }
                return r;
            })
            this.setState(dataList);
        })

        this.timeHandle = setTimeout(this.scanDeviceList,1000*60);

    }

    componentDidMount(){
        this.scanDeviceList();
        this.unSubscribe = store.subscribe(()=>{ this.setState(store.getState()); })


    }
    componentWillUnmount(){
        this.unSubscribe();
        if (this.timeHandle) clearTimeout(this.timeHandle);
    }

    render() {
        let {dataList,roomAlertList} = this.state;
        let alarmProcess = roomAlertList.filter(res=>res.alarm_process===1);

        //报警显示
        dataList = dataList.map(r => {
            r.src=r.src1;r.msg="";r.titleShow = r.title;
            for(let i=0; i<alarmProcess.length; i++){
                // 低电压报警
                if(alarmProcess[i].alert_type === 18){
                    if(r.type === alarmProcess[i].device_type){
                        r.src=r.src2;
                        break;
                    }
                }else if(alarmProcess[i].alert_type === 19){ //离线报警
                    if(r.type === getAlertType(alarmProcess[i].device_type)){
                        if (r.last_online_time && new Date(r.last_online_time).getTime()<new Date(alarmProcess[i].create_time).getTime()){
                            r.src = r.src3;
                            // r.msg = '离线';
                            r.titleShow = '设备离线: '+r.last_online_time;
                        }
                        break;
                    }
                }
                else{
                    if(r.type === alarmProcess[i].alert_type){
                        r.src=r.src2;
                        break;
                    }
                }
            }

            // 测试
            // if (r.type===5){
            //     r.src = r.src3;
            //     r.msg = '离线'
            //     r.title = '离线时间 '+r.last_online_time;
            // }


            return r;
        })


        return (
            <div className={Style.main}>
                <div className={Style.col1}>
                    <div><img src="/images/R06.png" alt="" /></div>
                </div>
                <div className={Style.col2}>
                    {dataList.map(res=>{
                        return <div className={Style.alert} key={res.type}>
                            <div className={Style.icon} >
                            <Tooltip color={"#141E6C"} placement="top" title={res.titleShow}>
                                <img className={res.show===1?"":Style.opacity} src={res.src} alt="" />
                            </Tooltip>
                            </div>
                            <div className={Style.text}>{res.msg}</div>
                        </div>
                    })}
                </div>
                <div className={Style.col3}>
                    <span>报警记录</span>
                </div>

            </div>
        )
    }
}
