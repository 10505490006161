import React from 'react';
import * as echarts from 'echarts';

export default class DebugBreathChart extends React.Component {
    xAxisData = [];
    yAxisData = [];
    eChart;
    option;
    yWidth = 500;
    breath = 0;
    initChart = ()=>{
        for (let i = 1; i <= this.yWidth; i++) {
            this.yAxisData.push(null);
        }

        if (this.yAxisData.length>this.yWidth){
            this.yAxisData.slice(-this.yWidth);
        }
        this.option = {
            animation: false,
            title: {
                text: `呼吸`,
                left: '20',
                textStyle: {
                    fontSize:"32px"
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross' },
            },
            grid: {
                left: 10,
                right: 10,
                top: '30%',
                height: '65%',
                containLabel: true
            },
            xAxis: {
                boundaryGap: false,
                data: this.xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#f2f2f2',
                    },
                },
            },
            yAxis: {
                min: -1.4,
                max: 1.4,
                interval: 0.7,
                axisTick: {
                    show: false,
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#f2f2f2',
                    },
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: '#999999',
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: ['#f2f2f2'],
                    },
                },
                // boundaryGapboundaryGap: true,
            },
            series: {
                symbol: 'none' /* 去掉小圆点 */,
                name: '当前值',
                type: 'line',
                data: this.yAxisData,
                smooth:false /* ,//显示为平滑的曲线 */,
                animationDurationUpdate:0,
                lineStyle: {
                    color: '#3DE0C0',
                },
            },
        };

        const heartMap = document.getElementById(`breathChart${this.props.id}`);
        if (this.eChart === undefined) {
            this.eChart = echarts.init(heartMap);
        }
        this.play()
    }


    componentDidMount() {
        this.initChart();
    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle);
        this.timeHandle = undefined
    }

    shouldComponentUpdate(nextProps, nextState) {
        // return nextProps.breathHeart.time!==this.props.breathHeart.time
        return true
    }

    playArr = [];
    frame = 2
    play=()=>{
        let wave = 0;
        let len = this.playArr.length;

        if (len>this.frame){
            for (let i=0;i<this.frame;i++){
                wave = this.playArr[0].b_wave;
                this.breath = this.playArr[0].breath;
                this.playArr.shift();
                this.yAxisData.push(wave);
                this.yAxisData.shift();
            }
            this.option.title.text = "呼吸";
            this.eChart.setOption(this.option);
        }

        let timeout = 50 * this.frame;
        if (len>20) timeout = timeout*(20/len);
        if (len>100) this.playArr.length=0;
        // console.log("len-timeout",len,timeout)
        setTimeout(()=>{
            this.play()
        },timeout)
    }

    componentDidUpdate() {
        let {bh_info} = this.props;
        bh_info?.forEach(e=>{
            this.playArr.push(e);
        })
        this.eChart.setOption(this.option);
    }

    render() {
        return (
            <>
                <div
                    id={`breathChart${this.props.id}`}
                    style={{padding:"0 20px", height: '100%',width:'100%'}}
                ></div>
            </>
        );
    }
}
