import React, { Component } from 'react'
import BreathMap from '../BreathMap/BreathMap'
import BreathMapOther from '../BreathMap/BreathMapOther'
import HeartMap from '../HeartMap/HeartMap'
import HeartMapOther from '../HeartMap/HeartMapOther'
import Style from './BreathHeart.module.css'
// import {nanoid} from 'nanoid';



export default class BreathHeart extends Component {
    constructor(props){
        super(props);
        this.mainRef = React.createRef();
        this.state ={mainWidth:0,currPage:0,}
    }

    pre(){
        const {mainWidth,currPage} = this.state;
        if(currPage>0){
            this.mainRef.current.scrollLeft -= mainWidth/2;
            this.setState({currPage:currPage-1})
        }
    }
    next(){
        const {bedList} = this.props;
        const {mainWidth,currPage} = this.state;
        if(currPage<bedList.length-2){
            this.mainRef.current.scrollLeft += mainWidth/2;
            this.setState({currPage:currPage+1})
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // if (nextProps.breathTime===nextState.breathTime ) {
        //     return false;
        // }
        return true;
    }

    componentDidMount(){
        this.setState({mainWidth:this.mainRef.current.offsetWidth});
    }
    render() {
        const {mainWidth,currPage} = this.state;
        let {bedList} = this.props;

        if(bedList===undefined){
            bedList = [];
        }else{
            const len = bedList.length
            for(let i = 0;i< 2-len; i++ ){
                bedList.push({no:10000+i,device_sn:10000+i,name:'',data:{breath:0,breath_wave:0,heart:0,heart_wave:0,device_src:'mm',device_sn:"",time:0}})
            }
        }

        return (
            <>
            {bedList?.length>2 && (
                <>
                    <div className={Style.pre} style={{opacity:currPage===0?"0.3":"1"}} onClick={
                        ()=>{
                            this.pre()
                        }
                    }>《</div>
                    <div className={Style.next} style={{opacity:currPage>=(bedList.length-2)?"0.3":"1"}} onClick={
                        ()=>{
                            this.next()
                        }
                    }>》</div>
                </>
            )}

            <div className={Style.main} ref={this.mainRef}>
                <div className={Style.bedList} style={{width:mainWidth/2*bedList.length}}>
                {bedList?.map(res=>{
                    return <div key={res.device_sn} className={Style.col} style={{width:mainWidth/2}}>
                    <div className={Style.bh}>

                        <div className={Style.bh_col1}>
                            <img src="/images/R04.png" alt="" style={{cursor:'pointer',width:"100%"}} onClick={()=>{
                            // console.log(res);
                            if (res.device_sn !==""){
                                this.props.showReport(res.device_sn)
                            }
                        }} /></div>


                        <div className={Style.bh_col2}>{res.name}</div>
                        <div className={Style.bh_col3}>
                            <div className={Style.bh_col3_c1}>
                                {res.data.time===0?(<img src="/images/R07-11.png" alt=""  style={{width:"100%"}}/>):(<img src="/images/R07-12.png" alt=""  style={{width:"100%"}}/>)}
                            </div>
                            <div className={Style.bh_col3_c2}>
                                {res.data.device_src  === 'mm' && (<HeartMap id={res.device_sn} breathHeart={res.data}></HeartMap>)}
                                {res.data.device_src  === 'old' && (<HeartMapOther id={res.device_sn} breathHeart={res.data}></HeartMapOther>)}
                                {res.data.device_src  === 'other' && (<HeartMapOther id={res.device_sn} breathHeart={res.data}></HeartMapOther>)}
                            </div>
                            <div className={Style.bh_col3_c3}>
                                {res.data.time===0?(<img src="/images/R08-11.png" alt=""  style={{width:"100%"}}/>):(<img src="/images/R08-12.png" alt=""  style={{width:"100%"}}/>)}
                            </div>
                            <div className={Style.bh_col3_c4}>
                                {res.data.device_src  === 'mm' && (<BreathMap id={res.device_sn} breathHeart={res.data}></BreathMap>)}
                                {res.data.device_src  === 'old' && (<BreathMapOther id={res.device_sn} breathHeart={res.data}></BreathMapOther>)}
                                {res.data.device_src  === 'other' && (<BreathMapOther id={res.device_sn} breathHeart={res.data}></BreathMapOther>)}
                            </div>
                        </div>
                    </div>
                </div>
                })}
                </div>
            </div>
            </>
        )
    }
}
