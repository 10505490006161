import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUser } from 'service/service';


function AuthRoute(props) {
  const {isLogin} = getUser();
  const backUrl = "/user/login";

  if (isLogin) {
    return <Route {...props} />
  } else {
    return <Redirect to={backUrl} />
  }
}

export default AuthRoute;
