import React, {Component} from 'react';
import {getPeopleNum} from 'service/service'
import Style from './PeopleCounting.module.css'


class PeopleCounting extends Component {
    state = {
        peopleNumber:0
    }
    scanPeopleNumberHandle;
    room_id = 0;
    token = "";

    // 人数统计
    scanPeopleNum = () => {
        const that = this;
        getPeopleNum({ room_id: that.room_id,token: that.token }).then((res) => {

            let peopleNumber = 0;

            if (res.success && res.data && res.data?.max_people){
                if(typeof peopleNumber === 'number' && !isNaN(peopleNumber)){
                    peopleNumber = res.data?.max_people;
                }
            }

            that.setState({peopleNumber:peopleNumber});
            that.scanPeopleNumberHandle = setTimeout(this.scanPeopleNum, 2000);
        });
    };

    componentDidMount() {
        document.title = "人数统计 - 苗米科技";
        let tokenMap = new Map();
        tokenMap.set("411","5f24e4528ca40c8f6fde4cafbf0b139e");//测试服
        tokenMap.set("446","af509551813f04c44edfb9eac7d8e96d");
        tokenMap.set("447","af509551813f04c44edfb9eac7d8e96d");
        tokenMap.set("375","516660ba5f3aba0f4f0cafeb1734929b");


        this.room_id = this.props.history.location.pathname.split("/").pop();
        this.token = tokenMap.get(this.room_id);
        this.scanPeopleNum();
    }
    componentWillUnmount() {
        if (this.scanPeopleNumberHandle) clearTimeout(this.scanPeopleNumberHandle)
    }
    render() {
        const {peopleNumber} = this.state;
        const v = "20211115";
        return (
            <>
                <div className={Style.main} style={{
                    backgroundImage:"url(/images/peoplecount/bg.png?v=2)",
                    backgroundRepeat:"no-repeat",
                    backgroundSize:"100% 100%",
                }}>
                    <div className={Style.hundreds}>
                        {peopleNumber>=100?(<img className={Style.num} alt={""} src={`/images/peoplecount/${Math.round(peopleNumber/100%10)}.png?v=${v}`} />):(<img className={Style.num} alt={""} src={`/images/peoplecount/00.png?v=${v}`} />)}
                    </div>

                    <div className={Style.tens}>
                        {peopleNumber>=10?(<img className={Style.num} alt={""} src={`/images/peoplecount/${Math.round(peopleNumber/10%10)}.png?v=${v}`} />):(<img className={Style.num} alt={""} src={`/images/peoplecount/00.png?v=${v}`} />)}
                    </div>

                    <div className={Style.ones}>
                        <img className={Style.num} alt={""} src={`/images/peoplecount/${Math.round(peopleNumber%10)}.png?v=${v}`} />
                    </div>
                </div>
            </>
        );
    }
}

export default PeopleCounting;
