import React from 'react';
import * as echarts from 'echarts';

export default class HeartMap extends React.Component {
  xAxisData = [];
  yAxisData = [];
  totalFlowRate;
  totalFlowRateOption;
  yWidth = 100;

  lastHeart = 0;
  lastHeartTime = 0;
  dataList = [];
  timeHandle = undefined;

  scanData = ()=>{
    clearTimeout(this.timeHandle);
    // console.log(this.dataList)
    if (this.lastHeartTime<new Date().getTime()-5000){
      this.timeHandle = undefined
      return;
    }


    this.totalFlowRateOption.title.text = `${this.lastHeart === 0 ? '--' : this.lastHeart}BPM`;
    let heart_wave = 0;
    let maxlen = 40;

    // console.log(this.dataList.length)

    if (this.dataList.length<1){

    }else if (this.dataList.length<=maxlen){
      heart_wave = this.dataList.length>0?this.dataList[0]:0;
      this.yAxisData.push(heart_wave);
      this.dataList.shift();
      if (this.yAxisData.length>this.yWidth){
        this.yAxisData.shift();
      }
    }else{
      for (let i=0;i<this.dataList.length-maxlen;i++){
        heart_wave = this.dataList[0];
        this.yAxisData.push(heart_wave);
        this.dataList.shift();
        if (this.yAxisData.length>this.yWidth){
          this.yAxisData.shift();
        }
      }
    }

    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.shift();
    }
    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }

      this.totalFlowRate.setOption(this.totalFlowRateOption);

    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }

    this.timeHandle = setTimeout(this.scanData,50);

  }


  componentDidMount() {
    // var data = [116,129,135,86,73,73,73,73,73,73,73,85,73,68,85,90,90,90,90,180,300];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.yWidth; i++) {
      this.yAxisData.push(null);
    }
    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }
    this.totalFlowRateOption = {
      animation: false,
      title: {
        text: `${this.lastHeart === 0 ? '--' : this.lastHeart}BPM`,
        right: '0vw',
        textStyle: {
          color: '#00B2FD',
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      grid: {
        left: 10,
        right: 10,
        top: '30%',
        height: '40%',
      },
      xAxis: {
        boundaryGap: false,
        data: this.xAxisData,
        axisLine: {
          show: true,
          lineStyle: {
              color: '#078CD6',
          },
      },
      },
      yAxis: {
        min: -1.2,
        max: 1.2,
        interval: 1.2,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: ['#078CD6'],
          },
        },
        boundaryGap: false,
      },
      series: {
        symbol: 'none' /* 去掉小圆点 */,
        name: '当前值',
        type: 'line',
        data: this.yAxisData /* ,smooth:true//显示为平滑的曲线 */,
        lineStyle: {
          color: 'red',
        },
      },
    };

    const heartMap = document.getElementById(`heartMap${this.props.id}`);
    if (this.totalFlowRate === undefined) {
      this.totalFlowRate = echarts.init(heartMap);
    }

    this.scanData();



  }

  componentWillUnmount() {
    clearTimeout(this.timeHandle);
    this.timeHandle = undefined
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.breathHeart.time!==this.props.breathHeart.time
  }


  componentDidUpdate() {
    // console.log(this.props);
    let {heart,heart_wave,time} = this.props.breathHeart;

    // 平衡心率值
    if (this.lastHeartTime < new Date().getTime()-900){
        if (this.lastHeart>0 && heart>10 && Math.abs(heart-this.lastHeart)>=6){
          let aHeart = ((heart-this.lastHeart)>0?1:-1)*(2+Math.round(2*Math.random()))
          this.lastHeart += aHeart;
        }else{
          this.lastHeart = heart;
        }
        this.lastHeartTime = new Date().getTime();
    }

    if(time && time>new Date().getTime()-1000){
      this.dataList.push(heart_wave);
    }


    if (this.timeHandle===undefined) this.scanData();

  }

  render() {
    return (
      <>
        <div
          id={`heartMap${this.props.id}`}
          style={{ height: '15vh',width:'13vw', backgroundColor: 'rgba(255, 255, 255, 0)' }}
        ></div>
      </>
    );
  }
}
