import React, {Component} from 'react';
// import Heatmap from 'heatmap.js';
import HotChart from "./HotChart/HotChart";
import DebugHeartChart from "./HeartChart/DebugHeartChart";
import Style from './Debug.module.css'
import DebugBreathChart from "./BreathChart/DebugBreathChart";
import DebugRangeBinTestChart from "./TestRangeChart/DebugRangeBinTestChart";
// import HotChart2 from "./HotChart/HotChart2";
class Debug extends Component {
    state={
        range_bin:[],
        range_bin_test:[],
        bh_info:[],
        has_people:0, //0无人 1有人
        close_to:[0,0],//0远离 1靠近
        heart:0,
        breath:0,
        pNum:0,
    }
    socket
    width = 800
    height = 200;
    handleData = (data)=>{
        let that = this;
        if(typeof(data)=='string'){
            data = JSON.parse(data);
        }
        if (data.type && data.type==="breath_new") {
            let now = new Date().getTime();
            // console.log(now-this.lastTime)
            this.lastTime = now;
            let bh_info = undefined;
            let range_bin = undefined;
            let range_bin_test = undefined;
            let has_people = 0;
            let close_to = [0,0]
            let heart =0;
            let breath = 0;
            let pNum = 0;

            if (data.bh_info){
                data.bh_info = data.bh_info.replace(/NaN/g, 0)
                bh_info = JSON.parse(data.bh_info);
                bh_info.forEach(e=>{
                    // console.log(JSON.stringify(e.targets),data.close_to)
                    // console.log(Math.sqrt(e.targets[1]*e.targets[1]+e.targets[2]*e.targets[2]+e.targets[2]*e.targets[2]))
                    pNum+=e.p_num;
                    heart = e.heart;
                    breath = e.breath;
                    // console.log(e.h_wave)
                })

                let bhs = bh_info.filter(e=> e.heart>0)
                if (bhs.length===0){
                    this.heart = 0
                }else{
                    // console.log(bhs)
                }
            }

            if (data.range_bin){
                try{
                    data.range_bin = data.range_bin.replace(/NaN/g, 0)
                    range_bin = JSON.parse(data.range_bin)
                }catch (e) {
                    console.log(data.range_bin)
                    console.log(e)
                }
            }

            if (data.range_bin_test){
                try{
                    data.range_bin_test = data.range_bin_test.replace(/NaN/g, 0)
                    range_bin_test = JSON.parse(data.range_bin_test)
                }catch (e) {
                    console.log(data.range_bin_test)
                    console.log(e)
                }
            }

            has_people = data.has_people;
            // console.log(data.close_to)
            close_to = JSON.parse(data.close_to)
            // console.log(data.has_people)
            that.setState({bh_info,range_bin,range_bin_test,has_people,close_to,heart,breath,pNum})

        }
    }
    lastTime=new Date().getTime();
    componentDidMount() {
        let that = this;
        this.socket = new WebSocket("wss://ws.consumer.wieyes.cn:8582/1777");
        this.socket.onopen = function (e) {
            console.log("socket onopen",e)
        };
        this.socket.onerror = function (e) {
            console.log("socket onerror",e)
        };
        this.socket.onclose = function (e) {
            console.log("socket onclose",e)
        };
        this.socket.onmessage = function (e) {
            that.handleData(e.data)
        };

        // this.getData()

    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle)
    }
    render() {
        let {breath,heart,range_bin,range_bin_test,bh_info,has_people,close_to,pNum} = this.state;
        return (
            <div className={Style.main}>
                <div className={Style.flexRow}>
                    <div style={{height:"40px",padding:"0 0 0 14px"}}>苗米科技</div>
                    <div className={Style.result}>
                        <div>{has_people===0?"无人":"有人"}</div>
                        <div>{close_to[0]===0?"远离":"靠近"}</div>
                        <div>点云数:{pNum}</div>
                        {/*<div>呼吸:{breath}</div>*/}
                        {/*<div>心率:{heart}</div>*/}
                    </div>
                </div>

                <div className={Style.flexRow}>
                    <div className={Style.breath}>
                        <DebugBreathChart bh_info={bh_info} ></DebugBreathChart></div>
                    <div className={Style.heart}>
                        <DebugHeartChart bh_info={bh_info} ></DebugHeartChart></div>
                </div>

                <div className={Style.flexRow}>
                    <div className={Style.hotChart}>
                        <HotChart range_bin={range_bin}></HotChart>
                    </div>
                    {/*<div className={Style.hotChart}>*/}
                    {/*    <HotChart2 range_bin={range_bin}></HotChart2>*/}
                    {/*</div>*/}
                </div>

                <div className={Style.flexRow}>
                    <div className={Style.rangeBintest}>
                        <DebugRangeBinTestChart range_bin_test={range_bin_test}></DebugRangeBinTestChart>
                    </div>
                </div>
            </div>
        );
    }
}

export default Debug;
