import React, {Component} from 'react';
import Heatmap from 'heatmap.js';

class HotChart extends Component {
    map
    data = []
    timeHandle
    clientWidth=100
    clientHeight=100
    hotData=[]

    componentDidMount() {
        let container = document.getElementById('hotMap')
        this.clientWidth = container.clientWidth
        this.clientHeight = container.clientHeight
        this.map = Heatmap.create({
            container,
            radius: 12,
            maxOpacity: 1,
            minOpacity: 0,
            blur: 1,
            backgroundColor:"blue",
            gradient: {
                // 0.1: "rgb(0,0,255)",
                0.8: "rgb(0,255,0)",
                0.95: "yellow",
                1: "rgb(255,0,0)"
            }
        });
        this.play()
    }

    playArr = [];
    play=()=>{
        let frame = this.playArr.length;
        // console.log("frame",frame)
        if (frame<=20) frame = 4
        if (frame>20) frame = 5
        if (frame>25) frame = 6
        if (frame>30) frame = 7
        if (frame>35) frame = 8
        if (frame>40) frame = 10


        for (let i=0;i<frame;i++){
            if (this.playArr.length>0){
                let binData = this.playArr[0];
                this.playArr.shift();

                this.hotData.map(e => {
                    e.x -= 5
                    return e;
                })


                binData.forEach(v => {
                    if (v>10){
                        this.hotData.push({
                            x: this.clientWidth,
                            y: this.clientHeight-v*5,
                            value: 5
                        })
                        if (this.hotData.length > this.clientWidth*100/5) this.hotData.shift();
                    }

                })
            }
        }

        if (this.map){
            this.map.setData({max:5,data:this.hotData})
        }

        setTimeout(()=>{
            this.play()
        },200)
    }



    componentWillUnmount() {
        clearTimeout(this.timeHandle)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {range_bin} = this.props;
        range_bin?.forEach(binData => {
            this.playArr.push(binData)
        })
    }

    render() {

        return (
            <div id="hotMap" style={{ border:"1px solid #f2f2f2", width: `100%`, height: `100%`,margin:'10px auto'}}></div>
        );
    }
}

export default HotChart;
