import React, {Component} from 'react';
import Style from './Ayi.module.css'
import AyiBreathChart from "./BreathChart/AyiBreathChart";
import AyiHeartChart from "./HeartChart/AyiHeartChart";
import AyiVarianceChart from "./Variance/AyiVarianceChart";
import VarianceChart2 from "./Variance/VarianceChart2";
import qs from 'query-string'
import {dateFormat} from "../../../utils/common";

class Ayi extends Component {
    state={
        bh_info:[],
    }
    socket = undefined
    historyJson = []

    breathHistory = []
    heartHistory = []

    bhRecord = []

    myBreath = 0;
    myHeart = 0;
    handleData = (data)=>{
        this.historyJson.push(data);

        let that = this;

        if(typeof(data)=='string'){
            data = JSON.parse(data);
        }
        if (data.type && data.type==="breath_new") {
            let bh_info = JSON.parse(data.bh_info);

            bh_info.forEach(e=>{
                this.breathHistory.push(e.b_wave)
                this.heartHistory.push(e.h_wave)
                this.breathHistory = this.breathHistory.slice(-600) //30秒的数据
                this.heartHistory = this.heartHistory.slice(-600) //30秒的数据

            })

            let myBH = this.getMyBH()
            myBH.h50 = this.getH50(myBH)
            myBH.b50 = this.getB50(myBH)

            that.setState({bh_info,myBH})

            myBH.time = dateFormat(new Date(),'H:i:s')
            myBH.b00 = bh_info[0].breath
            myBH.h00 = bh_info[0].heart
            this.bhRecord.push(myBH);
        }
    }

    getH50(myBH){
        let heart = myBH.h21
        if(this.myHeart === 0) this.myHeart = heart
        if (this.myHeart<heart){
            if (heart-this.myHeart<3){
                this.myHeart = heart
            }else{
                this.myHeart += 1+Math.round(Math.random()*2)
            }
        }else{
            if (this.myHeart-heart<3){
                this.myHeart = heart
            }else{
                this.myHeart -= 1+Math.round(Math.random()*2)
            }
        }
        return this.myHeart
    }

    getB50(myBH){
        let breath = myBH.b21
        if(myBH.b11>50){
            breath += parseInt(parseInt(myBH.b11-myBH.b21)*2/3)
            if (breath > myBH.b11) breath = myBH.b11
        }


        if(this.myBreath === 0) this.myBreath = breath
        if (this.myBreath<breath){
            if (breath-this.myBreath<3){
                this.myBreath = breath
            }else{
                this.myBreath += 1+Math.round(Math.random()*2)
            }
        }else{
            if (this.myBreath-breath<3){
                this.myBreath = breath
            }else{
                this.myBreath -= 1+Math.round(Math.random()*2)
            }
        }
        return this.myBreath
    }

    getMyBH = ()=>{
        let myBH = {
            b10: this.getBHCount1(this.breathHistory,0,0),
            h10: this.getBHCount1(this.heartHistory,0,0),
            b11: this.getBHCount1(this.breathHistory,5,0.01),
            h11: this.getBHCount1(this.heartHistory,2,0.005),
            b20: this.getBHCount2(this.breathHistory,0,0),
            h20: this.getBHCount2(this.heartHistory,0,0),
            b21: this.getBHCount2(this.breathHistory,5,0.01),
            h21: this.getBHCount2(this.heartHistory,2,0.005),
        }
        return myBH;
    }


    //波峰计算法
    // limitLen
    // limitHeight 当前波峰(波谷)与上一个波谷(波峰)的高度差阈值，小于这个值的滤掉
    getBHCount1 (arr,limitLen,limitHigh) {
        let tops = [] //波峰
        let lows = [] //波谷
        let topsIndex = [] //波峰位置
        let lowsIndex = [] //波谷位置

        for (let i = 0; i < arr.length; i++) {
            if (i === 0) { //第一个点不参与计算
                // if (arr[0] < arr[1]) {
                //     lows.push(arr[0])
                //     lowsIndex.push(0)
                // } else {
                //     tops.push(arr[0])
                //     topsIndex.push(0)
                // }
            } else if (i === arr.length - 1) { //最后一个点不参与计算
                // if (arr[i] < arr[i-1]) {
                //     lows.push(arr[i])
                //     lowsIndex.push(i)
                // } else {
                //     tops.push(arr[i])
                //     topsIndex.push(i)
                // }
            } else {
                //波峰
                if (arr[i] > arr[i - 1] && arr[i] > arr[i + 1]) {
                    tops.push(arr[i]);
                    topsIndex.push(i);

                    let isPop = false

                    //消除轻微波动
                    if (isPop == false && tops.length > 0 && lows.length > 0 && Math.abs(lows[lows.length - 1] - tops[tops.length - 1]) < limitHigh) {
                        tops.pop();
                        topsIndex.pop();
                        lows.pop();
                        lowsIndex.pop();
                        isPop = true;
                    }

                    if (isPop == false && lowsIndex.length > 0 && i - lowsIndex[lowsIndex.length - 1] < limitLen) {//消除邻近波动
                        tops.pop();
                        topsIndex.pop();
                        lows.pop();
                        lowsIndex.pop();
                        isPop = true;
                    }
                }
                //波谷
                if (arr[i] < arr[i - 1] && arr[i] < arr[i + 1]) {
                    lows.push(arr[i]);
                    lowsIndex.push(i);

                    let isPop = false

                    //消除轻微波动
                    if (isPop === false && tops.length > 0 && lows.length > 0 && Math.abs(tops[tops.length - 1] - lows[lows.length - 1]) < limitHigh) {
                        tops.pop();
                        topsIndex.pop();
                        lows.pop();
                        lowsIndex.pop();
                        isPop = true;
                    }

                    if (isPop===false && topsIndex.length > 0 && i - topsIndex[topsIndex.length - 1] < limitLen) { //消除邻近波动
                        tops.pop();
                        topsIndex.pop();
                        lows.pop();
                        lowsIndex.pop();
                    }
                }
            }
        }
        if (tops.length<2) tops.length=0
        return parseInt((tops.length*20*60/arr.length).toString())
    }

    getBHCount2 (arr,limitLen,limitHigh) {
        let tops = [] //过零 上升处
        let topsIndex = [] //过零位置
        for(let i = 0; i < arr.length; i++) {
            if(i>0 && i<arr.length - 1){
                if (arr[i - 1]<0 && arr[i] >=0  && arr[i+1]>0) {
                    tops.push(arr[i]);
                    topsIndex.push(i);

                    let start = (i>10)?(i-10):0
                    let isNeedPop = true
                    for (let j = start;j<i;j++){
                        if(Math.abs(arr[j])>limitHigh) {
                            isNeedPop = false
                            break;
                        }
                    }
                    if (isNeedPop){
                        tops.pop();
                        topsIndex.pop();
                    }
                }
            }
        }

        if (tops.length<2) tops.length=0
        return parseInt((tops.length*20*60/arr.length).toString())
    }

    lastTime=new Date().getTime();
    componentDidMount() {
        const {id} = qs.parse(this.props.history.location.search?.replace(/^\?/, ''));
        this.room_id = id;

        let that = this;
        this.socket = new WebSocket("wss://ws.consumer.wieyes.cn:8582/"+this.room_id);
        // this.socket = new WebSocket("ws://106.15.183.112:8080/websocket/"+new Date().getTime());
        this.socket.onopen = function (e) {
            console.log("socket onopen",e)
        };
        this.socket.onerror = function (e) {
            console.log("socket onerror",e)
        };
        this.socket.onclose = function (e) {
            console.log("socket onclose",e)
        };
        this.socket.onmessage = function (e) {
            that.handleData(e.data)
        };
        // this.getData()
    }

    exportJson = ()=>{
        let data = JSON.stringify(this.historyJson);
        let urlObject = window.URL || window.webkitURL || window;
        let export_blob = new Blob([data]);
        let save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = "bh"+new Date().getTime();
        save_link.click();
    }

    exportRecord = ()=>{
        let data = [];
        data.push("时间\t设备计算\t波峰计算\t波峰滤波\t过零计算\t过零滤波\t综合计算\t设备计算\t波峰计算\t波峰滤波\t过零计算\t过零滤波\t综合计算\n");
        this.bhRecord.forEach(e=>{
            data.push(e.time+"\t"+e.b00+"\t"+e.b10+"\t"+e.b11+"\t"+e.b20+"\t"+e.b21+"\t"+e.b50+"\t"+e.h00+"\t"+e.h10+"\t"+e.h11+"\t"+e.h20+"\t"+e.h21+"\t"+e.h50+"\n");
        })
        let urlObject = window.URL || window.webkitURL || window;
        let export_blob = new Blob(data);
        let save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a")
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = "bhRecord"+new Date().getTime();
        save_link.click();
    }


    componentWillUnmount() {
        clearTimeout(this.timeHandle)
    }
    render() {
        let {bh_info,myBH} = this.state;
        return (
            <div className={Style.main}>

                <div classNabe={Style.flexRow}>
                    <div className={Style.heart}><AyiHeartChart bh_info={bh_info}  myBH={myBH} ></AyiHeartChart></div>
                </div>

                <div className={Style.flexRow}>
                    <div className={Style.breath}><AyiBreathChart bh_info={bh_info} myBH={myBH} ></AyiBreathChart></div>
                </div>



                <div style={{clear:"both",margin:"20px"}}>
                    <button onClick={()=>{
                        this.exportRecord()
                    }}>导出呼吸心率记录</button>
                </div>

                <div className={Style.flexRow}>
                    <div className={Style.variance}><AyiVarianceChart bh_info={bh_info} ></AyiVarianceChart></div>
                </div>
                <div className={Style.flexRow}>
                    <div className={Style.variance2}><VarianceChart2 bh_info={bh_info} ></VarianceChart2></div>
                </div>

                <div className={Style.flexRow}>
                    <button onClick={()=>{
                        this.exportJson()
                    }}>导出</button>
                </div>
            </div>
        );
    }
}

export default Ayi;
