import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';
import {alertMap} from 'utils/common'
import Style from './AudioSwitch.module.css'
import store from 'store/index'


export default class AudioSwitch extends Component {
    constructor(props){
        super(props)
        this.audioRef = React.createRef();
        this.state = store.getState();
    }

    unSubscribe = ()=>{}
    audioPlaying = false;
    audioRepeatTimes = 3;
    audioPrePlay = () => {
        const {audioSwitch,audioCurrAlert} = this.state;
        // console.log(audioSwitch,this.audioPlaying,audioCurrAlert);
        if(audioSwitch===false || this.audioPlaying===true || audioCurrAlert===0) return;
        this.audioPlaying = true;
        this.audioDoPlay()
    };

    audioDoPlay = ()=> {
        const {audioSwitch,audioCurrAlert} = this.state;
        if(audioSwitch===false || audioCurrAlert===0){
            this.audioPlaying = false;
            this.audioRepeatTimes = 3;
            return;
        }
        console.log(this.audioRef);
        this.audioRef.current.audioEl.current.src = alertMap(this.state.audioCurrAlert).audio;
        this.audioRef.current.audioEl.current.play();
    };

    audioHandle = undefined
    componentDidMount(){
        this.unSubscribe = store.subscribe(()=>{this.setState(store.getState())});
        this.audioHandle = setInterval(() => {
            this.audioPrePlay();
        }, 100);
    }

    componentWillUnmount(){
        this.unSubscribe();
        if(this.audioHandle) clearInterval(this.audioHandle)
        this.setState = () => false;
    }


    render() {
        const {audioSwitch} = this.state;
        return (
            <div className={Style.main}>
                <ReactAudioPlayer src={""} autoPlay={false} ref={this.audioRef} onEnded={()=>{
                    if(this.audioRepeatTimes>0){
                        this.audioRepeatTimes--;
                        setTimeout(()=>{
                            this.audioDoPlay();
                        },500)
                    }else{
                        this.audioRepeatTimes=3;
                        this.audioPlaying = false;
                    }
                }}/>

                <a href="#!" onClick={(e)=>{
                    e.preventDefault();
                    store.dispatch({type:"audioSwitch",value:!audioSwitch})
                }}>
                {!audioSwitch && <img src={"/images/Vclose.png"} alt="" border="0" style={{width:"100%"}}/>}
                {audioSwitch && <img src={"/images/Vopen.png"}  alt="" border="0" style={{width:"100%"}}/>}
                </a>



            </div>
        )
    }
}
