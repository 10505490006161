import React, { Component } from 'react'
import Style from './AudioConfirm.module.css'
import store from 'store/index'

export default class AudioConfirm extends Component {
    constructor(props){
        super(props)
        this.state ={
            ...store.getState()
        };
    }

    componentDidMount(){
        this.unSubscribe = store.subscribe(()=>{this.setState(store.getState())});
        let audioLength = localStorage.getItem("audioLength");
        if(audioLength===null || audioLength===""){
            audioLength = "15";
            localStorage.setItem("audioLength",audioLength);
        }
        this.setState({
            audioLength: Number(audioLength)
        })
    }

    handleChange = (e)=>{
        localStorage.setItem("audioLength",e.target.value);
        this.setState({
            audioLength:e.target.value
        })
    }

    componentWillUnmount(){
        this.unSubscribe();
        this.setState = () => false;
    }



    render() {
        console.log(this.state)
        const {audioConfirm,audioLength} = this.state;
        console.log(audioLength)
        return (
            <>
                {audioConfirm &&  (
                    <>
                        <div className={Style.meng}></div>
                        <div className={Style.confirm}>
                            <img className={Style.lim01} src={"/images/lim01.gif"} alt="" />
                            <div className={Style.voice}><img src={"/images/li01.png"} alt="" /></div>
                            <div className={Style.txt}>请打开语音报警服务</div>
                            <div className={Style.select}>
                                <select value={audioLength} onChange={e=>{this.handleChange(e)}}>
                                    <option value={15}>播报15秒</option>
                                    <option value={60}>播报1分钟</option>
                                    <option value={600}>播报10分钟</option>
                                    <option value={-1}>处理告警前一直播报</option>
                                </select>
                            </div>
                            <div className={Style.button}>
                                <button className={Style.btn2} onClick={()=>{
                                    store.dispatch({type:"audioConfirm",value:false})
                                    store.dispatch({type:"audioSwitch",value:true})}}>确 定</button>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }
}
