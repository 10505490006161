import React, {Component} from 'react';
// import Heatmap from 'heatmap.js';
// import HotChart from "./HotChart/HotChart";
// import DebugHeartChart from "./HeartChart/DebugHeartChart";
// import Style from './Debug.module.css'
// import DebugBreathChart from "./BreathChart/DebugBreathChart";
// import DebugRangeBinTestChart from "./TestRangeChart/DebugRangeBinTestChart";
class Test extends Component {
    state={

    }

    componentDidMount() {
        this.socket = new WebSocket("ws://localhost:8080/websocket/breath");
        this.socket.onopen = function (e) {
            console.log("socket onopen",e)
        };
        this.socket.onerror = function (e) {
            console.log("socket onerror",e)
        };
        this.socket.onclose = function (e) {
            console.log("socket onclose",e)
        };
        this.socket.onmessage = function (e) {
            console.log("socket onmessage",e)
        };
    }

    sendMsg = (action,value)=>{
        let message = {action,value}
        this.socket.send(JSON.stringify(message))
    }

    componentWillUnmount() {
        clearTimeout(this.timeHandle)
    }
    render() {
        return (<div style={{
            width:"100vw",
            height:"100vh",
            backgroundImage:"url('/images/breath/test.jpeg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            backgroundPosition: 'center center',
        }}>
            <button onClick={()=>{
                this.sendMsg('auto','1')
            }}>test</button>
        </div>);
    }
}

export default Test;
