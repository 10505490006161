import React, { Component } from 'react'
import Style from './BreathHeart.module.css'
import HeartSimulationMap from "../HeartMap/HeartSimulationMap";
import BreathSimulationMap from "../BreathMap/BreathSimulationMap";
// import {nanoid} from 'nanoid';


export default class BreathHeartSimulation extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }

    componentDidMount(){
    }
    render() {
        return (
            <>
            <div className={Style.main}>
                <div className={Style.bedList} style={{width:"90vw"}}>
                    <div className={Style.col} style={{width:"70vw"}}>
                        <div className={Style.bh} style={{margin:"0 0 0 15vw"}}>
                        <div className={Style.bh_col1}></div>
                        <div className={Style.bh_col2}></div>
                        <div className={Style.bh_col3}  >
                            <div className={Style.bh_col3_c1}>
                                <img src="/images/R07-12.png" alt="" style={{width:'100%',height:'100%'}} />
                            </div>
                            <div className={Style.bh_col3_c2}>
                                <HeartSimulationMap id={1001} breathHeartSimulation={this.props.breathHeartSimulation} />
                            </div>
                            <div className={Style.bh_col3_c3}>
                                <img src="/images/R08-12.png" alt="" style={{width:'100%',height:'100%'}}  />
                            </div>
                            <div className={Style.bh_col3_c4}>
                                <BreathSimulationMap id={1002} breathHeartSimulation={this.props.breathHeartSimulation} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </>
        )
    }
}
