import React, { Component } from 'react'
import Style from './HealthReport.module.css';
import {dateFormat} from 'utils/common'
import {getHealthReport} from "../../../../service/service";
import {nanoid} from 'nanoid';


export default class HealthReport extends Component {

    getYesterday = ()=>{
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return dateFormat(date.getTime(),'Y-m-d');
    }

    getLastWeekDays = ()=>{
        const days = [];
        let date = new Date();
        for(let i=0;i<7;i++){
            date.setDate(date.getDate() - 1);
            days.push(dateFormat(date.getTime(),'Y-m-d'));
        }
        return days;
    }


    // 健康报告
    scanHealthReport = (device_sn,stat_date) => {
        const that = this;
        getHealthReport({
            room_id:localStorage.getItem("curr_room_id"),
            api_mode:localStorage.getItem("curr_api_mode"),
            token:localStorage.getItem("curr_token"),
            device_sn,stat_date,device_type:9}).then((res) => {
            that.setState({ healthReportData: res.data });
        });
    };


    state ={
        selectDay: this.getYesterday(),
        healthReportData:{}
    }

    componentDidMount() {
        this.scanHealthReport(this.props.device_sn,this.getYesterday());
    }


    render() {
        const {healthReportData,selectDay}= this.state;
        const {device_sn} = this.props;
        // console.log(healthReportData)
        return (
            <>
                <div className={Style.meng}></div>
                <div className={Style.main}>
                    <div style={{height:"3rem"}}>
                        <img src={"/images/R10-del.png"} 
                            style={{width:"3rem",float:"right",cursor:"pointer",margin:"0.3rem -2.7rem"}} alt=""
                            onClick={()=>{this.props.onClose()}} />
                    </div>
                    <div className={Style.left}>
                        <div className={Style.leftmain}>
                        <div className={Style.tjsj}>
                            <div>
                                <div className={Style.col1}>提交时间：<span>{healthReportData?.commit_date}</span></div>
                                <div className={Style.col2}>起止时间：<span>
                                    {healthReportData?.sleep_start} -------- {healthReportData?.sleep_end}</span></div></div>
                            <div><div>睡眠结构：
                                {healthReportData?.sleep_deep_percentage && (
                                    <span>深睡{healthReportData?.sleep_deep_percentage} 浅睡{healthReportData?.sleep_light_percentage}</span>
                                    )}
                            </div></div>
                        </div>
                        <div className={Style.event}>
                            呼吸事件
                        </div>

                        <table className={Style.table} width="100%">
                            <thead>
                                <tr>
                                    <th>异常类型</th>
                                    <th>异常次数</th>
                                    <th>占总次数</th>
                                    <th>累计时间</th>
                                    <th>占睡眠比</th>
                                    <th>单次最长</th>
                                    <th>发生时间</th>
                                    <th>平均指数</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                healthReportData?.list?.map(e=>{
                                    return <tr key={nanoid()}>
                                        <td align="center">{e.type_txt}</td>
                                        <td align="center">{e.num}</td>
                                        <td align="center">{e.num_percent}</td>
                                        <td align="center">{e.duration}</td>
                                        <td align="center">{e.duration_percent}</td>
                                        <td align="center">{e.single_time_long}</td>
                                        <td align="center">{e.happen_time}</td>
                                        <td align="center">{e.average}</td>
                                    </tr>
                                })
                            }

                            {
                                (healthReportData===undefined || JSON.stringify(healthReportData)==="{}") && (<tr key={nanoid()}>
                                    <td colSpan={8} align={"center"}> 没有睡眠数据</td>
                                </tr>)
                            }
                            </tbody>
                        </table>

                        <div className={Style.zhenduan}>诊断：<span>{healthReportData?.diagnosis}</span></div>

                            <div className={Style.cankao}>数据暂未经过临床验证，仅供参考</div>

                        </div>

                    </div>
                    <div className={Style.right}>
                        {this.getLastWeekDays().map(res=>{
                            return <div className={selectDay===res?Style.daysCurr:Style.days} key={res}
                            onClick={()=>{
                                console.log(res);
                                this.scanHealthReport(device_sn,res)
                                this.setState({selectDay:res})
                            }}
                            >{res}</div>
                        })}
                    </div>
                </div>
            </>
        )
    }
}


