import React, { Component } from 'react'
import MonitorIndex from 'components/Monitor/Index/Index'

export default class index extends Component {
    render() {
        return (
            <MonitorIndex history={this.props.history}></MonitorIndex>
        )
    }
}
