import React from 'react';
import * as echarts from 'echarts';

export default class BreathSimulationMap extends React.Component {
  heart = 0;
  xAxisData = [];
  yAxisData = [];
  totalFlowRate;
  totalFlowRateOption;
  yWidth = 20;
  componentDidMount() {
    // var data = [116,129,135,86,73,73,73,73,73,73,73,85,73,68,85,90,90,90,90,180,300];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.yWidth; i++) {
      this.yAxisData.push(null);
    }
    if (this.yAxisData.length>this.yWidth){
      this.yAxisData.slice(-this.yWidth);
    }
    this.totalFlowRateOption = {
      animation: false,
      title: {
        text: ``,
        right: '0vw',
        textStyle: {
          color: '#00B2FD',
          fontSize: 20,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
      },
      grid: {
        left: 10,
        right: 10,
        top: '20%',
        height: '40%',
      },
      xAxis: {
        boundaryGap: false,
        data: this.xAxisData,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#00B2FD',
          },
        },

      },
      yAxis: {
        min: 0,
        max: 25,
        interval: 1,
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: ['#00B2FD'],
          },
        },
        boundaryGap: false,
      },
      series: {
        symbol: 'none' /* 去掉小圆点 */,
        name: '当前值',
        type: 'line',
        data: this.yAxisData ,
        smooth:true,
        lineStyle: {
          // color: '#00B2FD',
        },
        areaStyle: {
          // opacity:0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#00B2FD'
            },
            {
              offset: 1,
              color: '#7ac9e8'
            }
          ])
        },
      },
    };

    const heartMap = document.getElementById(`breathMap${this.props.id}`);
    if (this.totalFlowRate === undefined) {
      this.totalFlowRate = echarts.init(heartMap);
      this.totalFlowRate.setOption(this.totalFlowRateOption,true)
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.breathHeartSimulation.time!==this.props.breathHeartSimulation.time
  }


  componentDidUpdate() {
    let {breath} = this.props.breathHeartSimulation;
    this.totalFlowRateOption.title.text = `${breath === 0 ? '--' : breath}BPM`;
    this.yAxisData.push(breath);
    this.yAxisData.shift();
    this.totalFlowRate.setOption(this.totalFlowRateOption);
  }


  render() {
    return (
        <>
          <div
              id={`breathMap${this.props.id}`}
              style={{ height: '15vh',width:"15vw", backgroundColor: 'rgba(255, 255, 255, 0)' }}
          ></div>
        </>
    );
  }
}
