import req from 'utils/request';
import api from 'config/api.config';

/** 登录接口*/
export const login = (params) => {
  return req.post(api.Login,params);
}

/** 退出登录接口 */
export const outLogin = () => {
  localStorage.removeItem("account_name");
  localStorage.removeItem("user_name");
  localStorage.removeItem("token_center");
  localStorage.removeItem("token_expires");
  localStorage.removeItem("curr_room_id");
  localStorage.removeItem("curr_token");
  localStorage.removeItem("curr_api_mode");
  localStorage.removeItem("curr_room_name");
  localStorage.removeItem("logo_url");
  // localStorage.clear();
}

/** 获取用户信息 */
export function getUser(){
  const userName = localStorage.getItem('user_name');
  const tokenCenter = localStorage.getItem('token_center');
  const tokenExpires = localStorage.getItem('token_expires');
  let isLogin = false;
  if(tokenCenter === null || (tokenExpires<new Date().getTime())){
      isLogin = false;
  }else{
      isLogin = true;
  }
  return {userName,tokenCenter,tokenExpires,isLogin}
}

// 房间列表
export const getRoomList = () => {
  return req.get('/center/all_room_info');
}

// 报警列表（所有房间）
export const getAlertList = () => {
  return req.get('/center/alarm_list');
}

// 报警处理
export const alarmProcess = (params) => {
  return req.post('/center/alarm_process',params);
}
// 报警处理 (误报处理)
export const alarmProcessMisreport = (params) => {
  return req.post('/center/alarm_process_misreport',params);
}
// 报警处理 (处理所有报警)
export const alarmProcessAll = (params) => {
  return req.post('/center/alarm_process_all',params);
}

// 房间详情
export const getRoomInfo = (params) => {
  return req.get('/center/room_info',{params});
}

// 报警列表（指定房间）
export const roomAlarmList = (params) => {
  return req.get('/center/room_alarm_list',{params});
}

// 实时报警信息
export const getZoneStatus = (params) => {
  return req.get('/center/zone_status',{params});
}

// 设备列表
export const getDeviceList = (params) => {
  return req.get('/center/device_list',{params});
}


// 获取人数
export const getPeopleNum = (params) => {
  return req.get('/public/get_people_num',{params});
}


// 获取监控报告
export const getHealthReport = (params) =>{
  return req.get('/center/health_report',{params});

  // console.log("getHealthReport.params",params)
  // return new Promise(function(resolve, reject) {
  //   return reject(
  //       {
  //         "success": true,
  //         "code": 0,
  //         "msg": "成功",
  //         "time": "2021-10-25 14:34:04",
  //         "data": {
  //           "commit_date": "2021-10-25 14:34:04",
  //           "sleep_start": "",
  //           "sleep_end": "",
  //           "sleep_deep_percentage": "0%",
  //           "sleep_light_percentage": "0%",
  //           "list": [
  //             {
  //               "type_txt": "低通气",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             },
  //             {
  //               "type_txt": "阻塞性",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             },
  //             {
  //               "type_txt": "中枢性",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             },
  //             {
  //               "type_txt": "混合型",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             },
  //             {
  //               "type_txt": "暂停",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             },
  //             {
  //               "type_txt": "总计",
  //               "num": 0,
  //               "num_percent": "0%",
  //               "duration": "00:00:00",
  //               "duration_percent": "0%",
  //               "single_time_long": 0,
  //               "happen_time": "00:00:00",
  //               "average": "0.00"
  //             }
  //           ],
  //           "diagnosis": "睡眠呼吸暂停综合证：正常"
  //         }
  //       }
  //   );
  // }).catch(function(reason) {return reason});
}


// 获取图片地址
export const getObsDetail = (params) => {
  return req.get('/center/obs_detail',{params});
}
