
import UserLogin from "pages/User/Login";
import MonitorIndex from "pages/Monitor/Index";
import MonitorRoom from "pages/Monitor/Room";
import MonitorRoomYuwei from "components/Monitor/Room/RoomYuwei";
import PeopleCounting from "pages/Monitor/PeopleCounting";
import MonitorBreath from "../components/Monitor/Breath/Breath";
import MonitorBreathDebug from "../components/Monitor/Breath/Debug";
import MonitorBreathTest from "../components/Monitor/Breath/Test";
import MonitorBreathAyi from "../components/Monitor/Breath/Ayi";
import MonitorBreathVariance from "../components/Monitor/Breath/Variance";


const routes = [
  {
    path: "/user/login",
    name: "登录",
    exact: true,
    component: UserLogin,
    needLogin: false,
  },
  {
    path: "/monitor/people-counting",
    name: "人数统计",
    exact: false,
    component: PeopleCounting,
    needLogin: false,
  },
  {
    path: "/monitor/index",
    name: "监控台",
    exact: true,
    component: MonitorIndex,
    needLogin: true,
  },
  {
    path: "/monitor/room",
    name: "房间监控",
    exact: true,
    component: MonitorRoom,
    needLogin: true,
  },
  {
    path: "/monitor/breath",
    name: "呼吸心率",
    exact: true,
    component: MonitorBreath,
    needLogin: false,
  },
  {
    path: "/monitor/breath/debug",
    name: "呼吸心率调试",
    exact: true,
    component: MonitorBreathDebug,
    needLogin: false,
  },
  {
    path: "/monitor/breath/ayi",
    name: "呼吸心率调试",
    exact: true,
    component: MonitorBreathAyi,
    needLogin: false,
  },
  {
    path: "/monitor/breath/variance",
    name: "呼吸心率调试",
    exact: true,
    component: MonitorBreathVariance,
    needLogin: false,
  },
  {
    path: "/monitor/breath/test",
    name: "呼吸心率调试",
    exact: true,
    component: MonitorBreathTest,
    needLogin: false,
  },
  {
    path: "/monitor/room/yuwei",
    name: "房间监控",
    exact: true,
    component: MonitorRoomYuwei,
    needLogin: true,
  },
];
export default routes;
