import React, {Component} from 'react';
import Style from './style.module.css'
import HeartChart from "./HeartChart/HeartChart";
import BreathChart from "./BreathChart/BreathChart";
import qs from 'query-string'
import {dateFormat} from 'utils/common'



let sums = [0,0,0];
function getRangeBinSum(data){
    let range_bin = JSON.parse(data.range_bin)
    let rangeMap = new Map();
    for(let i=0;i<64;i++){
        rangeMap.set(i,[])
    }

    range_bin.forEach(e=>{
        for(let i=0;i<64;i++){
            rangeMap.get(i).push(e[i])
        }
    })

    let sum = 0;
    for(let i=0;i<64;i++){
        let a = getPingJun(rangeMap.get(i))
        rangeMap.get(i).forEach(e=>{
            sum += Math.pow(e-a,2)
        })
    }

    sums.push(sum);sums.shift();

    sum = (sums[0]+sums[1]+sums[2])/3
    // console.log("sums",sum,sums);
    return sum;
}
function getPingJun(arr){
    if(arr.length<1) return 0;
    let sum = 0;
    arr.forEach(e=>{
        sum += Number(e)
    })
    return sum/arr.length
}

class Breath extends Component {
    state = {
        heart:0,myHeart:0,
        breath:0,myBreath:0,
        is_sleep:0,
        hasPeople:0,
        hasSport:0,
        hasHeart:0,
        hasBreath:0,
        bh_info:[],
        heartMax:0,
        heartMin:0,
        breathMax:0,
        breathMin:0,
        auto:1,
        heartStatus:"--",
        breathStatus:"--",
        online:0,
        alertTime:"",
        alertMsg:"呼吸正常"
    }

    socket1;
    socket2;
    heartTime1 = new Date().getTime();
    heartTime2 = new Date().getTime();
    checkHeartTimeoutHandle;
    room_id = 0;


    auto = 1;
    heart = 0;
    breath = 0;

    has_people = 0
    historyHeart = [];
    historyBreath = [];
    historyHeartWaves = [];
    historyBreathWaves = [];

    hasHeart = 0;
    hasBreath = 0;
    hasPeoples =[];
    lastBreathTime = 0;

    handleData = (data)=>{
        let that = this;

        let online = 0

        if(typeof(data)=='string'){
            data = JSON.parse(data);
        }
        if (data.type && data.type==="heart"){
            this.heartTime1 = new Date().getTime();
        }
        if (new Date().getTime()-this.lastBreathTime>5000){
            online = 0;
            this.setState({
                online,
                hasPeople:0,
                hasSport:0,
                heartStatus: "--",
                breathStatus:"--"
            })
        }else{
            online = 1;
        }

        if(data?.type == "alert") this.getAlert(data) // 苗米轨迹


        // console.log(data)
        if (data.type && data.type==="breath_new"){
            this.lastBreathTime = new Date().getTime()
            let bh_info = JSON.parse(data.bh_info);
            let heart=0;
            let breath=0;
            let heartWave = 0;
            let breathWave = 0;
            let heartMax=0;
            let heartMin=0;
            let breathMax=0;
            let breathMin=0;
            let close_to = JSON.parse(data.close_to)[0];

            // console.log(bh_info.length)

            bh_info.forEach(e=>{
                // console.log(e.heart,e.breath)
                if (e.heart>0) heart = e.heart;
                if (e.breath>0) breath = e.breath;
                heartWave = e.h_wave;
                breathWave = e.b_wave;

                //判断有无呼吸，心率
                this.historyHeartWaves.push(heartWave)
                this.historyBreathWaves.push(breathWave)
                if (this.historyHeartWaves.length>1200) this.historyHeartWaves.shift();
                if (this.historyBreathWaves.length>1200) this.historyBreathWaves.shift();
            })




            if (this.auto === 1){//自动
                this.heart = heart;
                this.breath = breath;

                //计算呼吸心率值
                let myHeart = heart;
                let myBreath = breath;
                // let myHeart = this.countHeart(this.historyHeartWaves);
                // let myBreath = this.countBreath(this.historyBreathWaves);

                if (myHeart > 100)  myHeart = parseInt((Math.random() * (98 - 94 + 1) + 94).toString());
                if (myHeart < 60 && myHeart>0)  myHeart = parseInt((Math.random() * (70 - 65 + 1) + 65).toString());

                // console.log(myHeart,myBreath)
                that.setState({myHeart,myBreath})

            }else{//手动
                let random = Math.floor(Math.random()*2);


                if (random===1){
                    if (this.heart>0) this.heart = this.heart + parseInt(Math.random() * 3)
                    if (this.breath>0) this.breath = this.breath + parseInt(Math.random() * 2)
                }else{
                    if (this.heart>0) this.heart = this.heart - parseInt(Math.random() * 3)
                    if (this.breath>0)  this.breath =this.breath - parseInt(Math.random() * 2)
                }

                heart = this.heart;
                breath = this.breath;
            }

            //计算最大值，最小值
            this.historyHeart.push(heart);
            this.historyBreath.push(breath);
            // console.log("this.historyHeart",this.historyHeart.length)


            if (this.historyHeart.length>10) this.historyHeart.shift();
            if (this.historyBreath.length>10) this.historyBreath.shift();

            if (this.historyHeart.length>1){
                heartMax = Math.max(...this.historyHeart);
                heartMin = Math.min(...this.historyHeart);
            }
            if (this.historyBreath.length>1){
                breathMax = Math.max(...this.historyBreath);
                breathMin = Math.min(...this.historyBreath);
            }

            if (heartMax<30) heartMax = 0;
            if (heartMin<30) heartMin = 0;
            if (breathMax<0) breathMax = 0;
            if (breathMin<0) breathMin = 0;

            //判断是否有心跳
            this.hasHeart = this.hasHeartFun(this.historyHeartWaves)
            //判断是否有呼吸
            this.hasBreath = this.hasBreathFun(this.historyBreathWaves)

            //判断是否有人
            // this.hasPeoples.push(Number(has_people))
            // if (this.hasPeoples.length>10) this.hasPeoples.shift();
            // this.hasPeople = this.hasPeopleFun(this.hasPeoples,this.hasPeople);

            //呼吸心率状态
            let heartStatus=""
            let breathStatus = "";

            if (this.state.myHeart<60){
                heartStatus = "--"
            }else if ((new Date().getHours()>=21 && new Date().getHours()<=6 && this.state.myHeart<30) || (new Date().getHours()<=21 && new Date().getHours()>=6 && this.state.myHeart<60)){
                heartStatus = "心率过缓"
            }else if(this.state.myHeart>100){
                heartStatus = "心率过速"
            }else{
                heartStatus = "心率正常"
            }

            if (this.state.myBreath<10){
                breathStatus = "--"
            }else if (this.state.myBreath<12){
                breathStatus = "呼吸过缓"
            }else if(this.state.myBreath>24){
                breathStatus = "呼吸过速"
            }else{
                breathStatus = "呼吸正常"
            }

            let rangeBinSum = getRangeBinSum(data)


            let auto = this.auto
            let hasHeart = this.hasHeart;
            // let hasBreath = this.hasBreath;

            let hasBreath = 0;
            let hasPeople = 0;
            let hasSport = 0;

            if(rangeBinSum>20) hasPeople = 1
            if(rangeBinSum>100) hasBreath = 1
            if(rangeBinSum>10000) hasSport = 1


            if (hasPeople==0 || hasSport==1){
                heart = 0;
                breath = 0;
                this.historyBreath.length = 0;
                this.historyHeart.length = 0;
            }


            that.setState({
                online,bh_info,heart,breath,heartMax,heartMin,breathMax,breathMin,heartStatus,breathStatus,hasHeart,hasBreath,hasPeople,hasSport,close_to,auto
            })


            // console.log({heart,breath,hasPeople,hasHeart,hasBreath})
            // console.log({hasHeart,hasBreath})
            // console.log(bh_info)
        }
    }

    getAlert(data){
        // const ALERT_TYPE_BREATH_SUSPEND = 20;//呼吸暂停
        // const ALERT_TYPE_BREATH_LOW = 21;//呼吸过缓
        // const ALERT_TYPE_BREATH_QUICK = 22;//呼吸过速
        // const ALERT_TYPE_HEART_LOW = 23;//心率过缓
        // const ALERT_TYPE_HEART_QUICK = 24;//心率过速
        console.log("alert",data);
        let alertTime = dateFormat(new Date().getTime(),'H:i')

        this.setState({
            alertTime,
            alertMsg:this.getAlertTypeName(data.alert_type)
        })
    }
    getAlertTypeName(alert_type){
        if(alert_type==20){
            return "呼吸暂停";
        }else if(alert_type==21){
            return "呼吸过缓";
        }else if(alert_type==22){
            return "呼吸过速";
        }else if(alert_type==23){
            return "心率过缓";
        }else if(alert_type==24){
            return "心率过速";
        }
        return alert_type;
    }

    hasHeartFun = (historyHeartWaves)=>{
        let sum = 0;
        let hasHeart = 0;
        if (historyHeartWaves.length>30){
            historyHeartWaves.slice(-30).forEach(e=>{
                sum+=Math.abs(e)
            })
            if (sum<0.06){//没有心跳，历史数据清空
                // this.historyHeartWaves.length = 0;
                // this.historyHeart.length = 0;
            }else{
                hasHeart = 1;
            }
        }
        return hasHeart;
    }
    hasBreathFun = (historyBreathWaves)=>{
        let sum = 0;
        let hasBreath = 0;
        if (historyBreathWaves.length>30){
            historyBreathWaves.slice(-30).forEach(e=>{
                sum+=Math.abs(e)
            })
            if (sum<0.3){//没有呼吸，历史数据清空
                // this.historyBreathWaves.length = 0;
                // this.historyBreath.length = 0;
            }else{
                hasBreath = 1;
            }
        }

        // console.log(sum)
        // return sum;
        return hasBreath;
    }
    hasPeopleFun = (hasPeoples,hasPeople)=>{
        console.log(hasPeoples)
        let len = hasPeoples.length;
        if (len>9){
            //连续4个无人，肯定是无人
            if(hasPeoples[len-1]===0 && hasPeoples[len-2]===0 && hasPeoples[len-3]===0 && hasPeoples[len-4]===0){
                hasPeople = 0;
                // console.log("无人")
                return hasPeople;
            }

            //连续4个有人，肯定是有人
            if(hasPeoples[len-1]===1 && hasPeoples[len-2]===1 && hasPeoples[len-3]===1&& hasPeoples[len-4]===1){
                hasPeople = 1;
                // console.log("有人")
                return hasPeople;
            }

            let sum = 0;
            for (let i=0;i<10;i++){
                sum+=Math.abs(hasPeoples[len-1-i])
            }
            if (sum<8){
                hasPeople = 0;
                // console.log("无人",sum)
                return hasPeople;
            }
        }
        return hasPeople;
    }

    countBreath = (arrs) => {
        let len = arrs.length;
        //小于5秒不计算
        if (len<100) return 0;
        //10秒内算不准，先随机一个

        if (len<200) return 17+parseInt((Math.random()*8).toString())
        if (len>1200) arrs = arrs.slice(-1200); //最多取60秒数据

        //先计算十秒内的呼吸值
        // let breath10 = parseInt((this.getTopLow(arrs.slice(-200),8).tops.length*6).toString());
        let breath = parseInt((this.getTopLow(arrs,"breath").tops.length*1200/arrs.length).toString());
        // console.log(breath10,breath)
        return breath
    }
    countHeart = (arrs) => {
        let len = arrs.length;
        //累积5秒后开始计算
        if (len<100) return 0;
        //最多取最近半分钟的数据
        if (len>1200) arrs = arrs.slice(-1200);
        let topNum = this.getTopLow(arrs,"heart").tops.length;
        // console.log(arrs.length,topNum)
        let heart = parseInt((topNum*1200/arrs.length).toString());
        return heart;
    }


    getTopLow (arr,type) {
        let tops = [] //波峰
        let lows = [] //波谷
        let topsIndex = [] //波峰位置
        let lowsIndex = [] //波谷位置

        let limitLen = 0,limitHeight = 0;
        if (type==="breath") {
            limitLen = 5;
            limitHeight = 0.1;
        }
        if (type==="heart") {
            limitLen = 2;
            limitHeight = 0.015;
        }


        for(let i = 0; i < arr.length; i++) {
            if (i === 0) {
                // if (arr[0] < arr[1]) {
                //     lows.push(arr[0])
                //     lowsIndex.push(0)
                // } else {
                //     tops.push(arr[0])
                //     topsIndex.push(0)
                // }
            } else if (i === arr.length - 1) {
                // if (arr[i] < arr[i-1]) {
                //     lows.push(arr[i])
                //     lowsIndex.push(i)
                // } else {
                //     tops.push(arr[i])
                //     topsIndex.push(i)
                // }
            } else {
                if (arr[i] > arr[i - 1] && arr[i] > arr[i + 1]) {
                    tops.push(arr[i]);
                    topsIndex.push(i);

                    //消除轻微波动
                    if (tops.length>0 && Math.abs(lows[lows.length-1]-tops[tops.length-1])<limitHeight){
                        tops.pop(); topsIndex.pop();lows.pop(); lowsIndex.pop();
                    }

                    //消除邻近波动
                    if (lowsIndex.length>0 && (i-lowsIndex[lowsIndex.length-1]<limitLen || Math.abs(lows[i-1]-tops[i])<2)){
                        tops.pop(); topsIndex.pop();
                        lows.pop(); lowsIndex.pop();
                    }
                }
                if (arr[i] < arr[i - 1] && arr[i] < arr[i + 1]) {
                    lows.push(arr[i]);
                    lowsIndex.push(i);

                    //消除轻微波动
                    if (tops.length>0 && Math.abs(tops[tops.length-1]-lows[lows.length-1])<limitHeight){
                        tops.pop(); topsIndex.pop();lows.pop(); lowsIndex.pop();
                    }

                    //消除邻近波动
                    if (topsIndex.length>0 && i-topsIndex[topsIndex.length-1]<limitLen){
                        tops.pop(); topsIndex.pop();lows.pop(); lowsIndex.pop();
                    }
                }
            }
        }


        return {
            tops,
            lows,
            topsIndex,
            lowsIndex
        }
    }



    changeData = (data)=>{
        if (data==="heart") return;
        let {type,value} = JSON.parse(data);
        // console.log(type,value)
        if (type === "auto") this.auto = value;
        if (type === "breath") {
            this.breath = this.breath + value
            if (this.breath<0) this.breath = 0
        }
        if (type === "heart") {
            this.heart = this.heart + value
            if (this.heart<0) this.heart = 0
        }
        this.setState({
            auto:this.auto,breath:this.breath,heart:this.heart
        })
        // console.log(data)
    }

    initSocket1 = ()=>{
        let that = this;
        if(this.socket1) this.socket1.close();
        this.socket1 = new WebSocket("wss://ws.consumer.wieyes.cn:8582/"+this.room_id);
        this.socket1.onopen = function (e) {
            console.log("socket1 onopen")
            that.heartTime1 = new Date().getTime();
        };
        this.socket1.onerror = function (e) {
            console.log("socket1 onerror")
        };
        this.socket1.onclose = function (e) {
            console.log("socket1 onclose")
        };
        this.socket1.onmessage = function (e) {
            that.handleData(e.data)
        };
    }

    initSocket2 = ()=>{
        let that = this;
        if(this.socket2) this.socket2.close();
        this.socket2 = new WebSocket("ws://106.15.183.112:8080/websocket/"+new Date().getTime());
        this.socket2.onopen = function (e) {
            console.log("socket2 onopen")
            that.heartTime2 = new Date().getTime();
        };
        this.socket2.onerror = function (e) {
            console.log("socket2 onerror")
        };
        this.socket2.onclose = function (e) {
            console.log("socket2 onclose")
        };
        this.socket2.onmessage = function (e) {
            that.heartTime2 = new Date().getTime();
            // console.log(e.data)
            that.changeData(e.data)
        };
    }

    checkHeart = () => {
        let that = this;


        if(this.socket2.readyState===1){
            this.socket2.send("heart");
        }
        if(this.heartTime1<new Date().getTime()-6000){
            this.initSocket1();
        }
        if(this.heartTime2<new Date().getTime()-6000){
            this.initSocket2();
        }
        if(this.checkHeartTimeoutHandle) clearTimeout(this.checkHeartTimeoutHandle)
        this.checkHeartTimeoutHandle = setTimeout(() => {
            that.checkHeart()
        }, 2000);
    }


    componentDidMount() {
        let that = this;
        const {id} = qs.parse(this.props.history.location.search?.replace(/^\?/, ''));
        this.room_id = id;
        this.initSocket1()
        this.initSocket2()
        setTimeout(()=>{
            that.checkHeart();
        },5000)
    }
    componentWillUnmount(){
        this.setState = () => false;
        if(this.checkHeartTimeoutHandle) clearTimeout(this.checkHeartTimeoutHandle)
    }





    render() {
        let {online,alertMsg,alertTime,heart,myHeart,heartMax,heartMin,breath,myBreath,breathMax,breathMin,hasBreath,hasHeart,hasPeople,hasSport,close_to,auto} = this.state;
        // console.log(hasPeople,hasHeart)
        // console.log(breath,heart)
        myBreath = breath;
        myHeart = heart;

        return (
            <div className={Style.main}>
                <div className={Style.left}>
                    <div className={Style.logo}>
                        <img src={'/images/breath/logo.png'} alt={""}></img>
                    </div>
                    <div className={Style.menu1}>
                        <div className={Style.menu1Img}><img src={'/images/breath/menu-index.png'} alt={""}></img> </div>
                        <div className={Style.menu1Txt}>首页</div>
                    </div>
                    <div className={Style.menu2}>
                        <div className={Style.menu2Img}><img src={'/images/breath/menu-sleep.png'} alt={""}></img> </div>
                        <div className={Style.menu2Txt}>睡眠</div>
                    </div>
                    <div className={Style.menu2}>
                        <div className={Style.menu2Img}><img src={'/images/breath/menu-tidong.png'} alt={""}></img> </div>
                        <div className={Style.menu2Txt}>体动</div>
                    </div>
                    <div className={Style.menu2}>
                        <div className={Style.menu2Img}><img src={'/images/breath/menu-lichuang.png'} alt={""}></img> </div>
                        <div className={Style.menu2Txt}>离床</div>
                    </div>
                </div>
                <div className={Style.center}>

                    <div className={Style.row0}>
                        <div className={Style.user}>
                            <div className={Style.user_head}><img src={'/images/breath/head.png'} alt={""}></img></div>
                            <div className={Style.user_name}>
                                <div>张素英 <span style={{color:"#999999"}}>（{online==0?"离线":"在线"}）</span></div>
                                <div className={Style.online}><div className={online==0?Style.user_online0:Style.user_online1}></div></div>
                                <div><img src={'/images/breath/girl.png'} alt={""}></img></div></div>
                        </div>
                        <div className={Style.top}>
                            <div className={Style.tixing}><img src={'/images/breath/tixing.png'} alt={""}></img></div>
                            <div className={Style.useradd}>
                                <img src={'/images/breath/useradd.png'} alt={""}></img>
                                <div>添加新用户 {auto===0?"··":"·"}</div>
                            </div>
                        </div>
                    </div>
                    <div className={Style.row1}>
                        <div className={Style.cTitle}>健康感知器</div>
                    </div>
                    <div className={Style.row2}>
                        <div style={{
                            backgroundImage:"url('/images/breath/bg-tidong.png')",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center center',
                        }} className={Style.tidong}>
                            <div className={Style.tidong1}>体动</div>
                            <div className={Style.tidong2}><img src={'/images/breath/tidong-1.png'} alt={""}></img></div>
                            <div className={Style.tidong3}>{hasSport==0?"静止中":"运动中"}</div>
                        </div>

                        <div style={{
                            backgroundImage:"url('/images/breath/bg-lichuang.png')",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            backgroundPosition: 'center center',
                            marginLeft:"5px"
                        }} className={Style.tidong}>

                            <div className={Style.tidong1}>离床</div>
                            <div className={Style.tidong2}><img src={'/images/breath/lichuang-1.png'} alt={""}></img></div>
                            <div className={Style.tidong3}>{hasPeople==0?"离床":"在床"}</div>
                        </div>

                        <div style={{
                            display:"flex",
                            position: "relative",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor:"#81caa0",
                            marginLeft:"5px"
                    }} className={Style.tidong}>

                        <div className={Style.alertTitle}>{alertMsg}</div>
                        <div className={Style.alertTime}>{alertTime==""?
                            <img src={'/images/breath/shuye.png'} alt={""}></img>:alertTime}</div>
                    </div>



                    </div>
                    <div className={Style.row3}>
                        <div className={Style.monitorTitle}>实时监测</div>
                    </div>
                    <div className={Style.row4}>
                        <div className={Style.heart}>
                            <div className={Style.row41}>
                                <div className={Style.heartTop}>
                                    <div className={Style.topLeft}>
                                        <div className={Style.heartImg}><img src={'/images/breath/heart.png'} alt={""}></img></div>
                                        <div className={Style.heartTxt}>ECG</div>
                                    </div>
                                    <div className={Style.topRight}>
                                        <div className={Style.upImg}><img src={'/images/breath/up.png'} alt={""}></img></div>
                                        <div className={Style.upTxt}>{heartMax}</div>
                                        <div className={Style.upImg}><img src={'/images/breath/down.png'} alt={""}></img></div>
                                        <div className={Style.upTxt}>{heartMin}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={Style.row42}>
                                <div className={Style.heartChart}><HeartChart heart={heart} hasHeart={hasHeart} hasPeople={hasPeople} hasSport={hasSport} close_to={close_to}></HeartChart></div>
                            </div>
                        </div>
                        <div className={Style.heart2}>

                            <div style={{
                                backgroundImage:"url('/images/breath/bg-rr.png')",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100% 100%',
                                backgroundPosition: 'center center',
                            }} className={Style.hr}>
                                <div className={Style.rr1}><span>HR
                            </span></div>
                                <div className={Style.hr2}>
                                    <span>{hasPeople === 1 && hasSport==0 && myHeart>=30?myHeart:"--"}</span>bpm</div>
                                <div className={Style.rr3}>{this.state.heartStatus}</div>
                            </div>
                        </div>
                    </div>
                    <div className={Style.row5}>
                        <div className={Style.breath}>
                            <div className={Style.row41}>
                                <div className={Style.heartTop}>
                                    <div className={Style.topLeft}>
                                        <div className={Style.heartImg}><img src={'/images/breath/breath.png'} alt={""}></img></div>
                                        <div className={Style.heartTxt}>RESP</div>
                                    </div>
                                    <div className={Style.topRight}>
                                        <div className={Style.upImg}><img src={'/images/breath/up.png'} alt={""}></img></div>
                                        <div className={Style.upTxt}>{breathMax}</div>
                                        <div className={Style.upImg}><img src={'/images/breath/down.png'} alt={""}></img></div>
                                        <div className={Style.upTxt}>{breathMin}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={Style.row42}>
                                <div className={Style.heartChart}><BreathChart breath={breath} hasBreath={hasBreath} hasPeople={hasPeople} hasSport={hasSport}></BreathChart></div>
                            </div>
                        </div>

                        <div className={Style.breath2}>
                            <div style={{
                                backgroundImage:"url('/images/breath/bg-rr.png')",
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '100% 100%',
                                backgroundPosition: 'center center',
                            }} className={Style.rr}>
                                <div className={Style.rr1}><span>RR
                            </span> </div>
                                <div className={Style.rr2}><span>{(hasPeople === 1 && hasSport==0 && myBreath>=0)?myBreath:"--"}</span>bpm</div>
                                <div className={Style.rr3}>{this.state.breathStatus}</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Breath;
